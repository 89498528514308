import React, { useState, useEffect  } from 'react';

const InputTransaction = ({ budget, transaction_details, showModal, closeModal }) => {
  // Get the url from the env file
  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }

  const authToken = localStorage.getItem('authToken');

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [selectedDate, setSelectedDate] = useState(getTodayDate());
  const [formData, setFormData] = useState({
    transactionType: '',
    transactionName: '',
    transactionAmount: '',
    budgetCategory: '',
    transactionDate: getTodayDate(),
  });

  useEffect(() => {
    // Set formData values from transaction_details when it is provided
    if (transaction_details) {
      setFormData({
        transactionType: transaction_details.type || '',
        transactionName: transaction_details.name || '',
        transactionAmount: transaction_details.price ? transaction_details.price.toString() : '',
        budgetCategory: transaction_details.category ? transaction_details.category.toString() : '',
        transactionDate: transaction_details.date || getTodayDate(),
      });
    }
  }, [transaction_details]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    // Create the API request data format
    const apiRequestData = {
      type: formData.transactionType,
      name: formData.transactionName,
      price: parseFloat(formData.transactionAmount),
      date: formData.transactionDate,
      category: parseInt(formData.budgetCategory, 10),
    };
  
    try {
      if (transaction_details) {
        apiRequestData.action = 'Edit'
        apiRequestData.id = transaction_details.id
        console.log(apiRequestData)
          // Make the API post request
        const response = await fetch(`${baseUrl}/api/edit-transaction`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
            // Add any additional headers if needed
          },
          body: JSON.stringify(apiRequestData),
        });

        // Check if the request was successful
        if (response.ok) {
          console.log('API request successful:', apiRequestData);
          // Clear the form data or perform any other necessary actions
          setFormData({
            transactionType: '',
            transactionName: '',
            transactionAmount: '',
            budgetCategory: '',
            transactionDate: getTodayDate(),
          });
          // Close the modal
          closeModal();
        } else {
          // Handle the case where the API request was not successful
          console.error('API request failed:', response.statusText);
        }
        
      }
      else{
         // Make the API post request
         const response = await fetch(`${baseUrl}/api/add-transactions`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
            // Add any additional headers if needed
          },
          body: JSON.stringify(apiRequestData),
          });

          // Check if the request was successful
          if (response.ok) {
            console.log('API request successful:', apiRequestData);
            // Clear the form data or perform any other necessary actions
            setFormData({
              transactionType: '',
              transactionName: '',
              transactionAmount: '',
              budgetCategory: '',
              transactionDate: getTodayDate(),
            });
            // Close the modal
            closeModal();
          } else {
            // Handle the case where the API request was not successful
            console.error('API request failed:', response.statusText);
          }
        } 
      }
    catch (error) {
      // Handle any network-related errors
      console.error('Error making API request:', error);
    }
  
      
  };

  return (
    <>
      {showModal && (
        <div className="font-inter fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="bg-slate-800 absolute inset-0 bg-gray-800 opacity-75"
            onClick={closeModal}
          ></div>
          <div className="bg-white px-8 py-4 rounded-lg z-50 shadow-lg sm:w-[500px] !mx-2">
            <h3 className="text-[14pt] mb-6 uppercase font-bold">
              {transaction_details ? 'Edit transaction' : 'Create a new transaction'}
            </h3>
            {transaction_details ? (
              transaction_details.plaid_data && (
                <button onClick={() => { console.log(transaction_details.plaid_data) }}>
                  View Full Plaid Data
                </button>
              )
            ) : (
              <></>
            )}
            <form className="card-body" onSubmit={handleFormSubmit}>
              <div className="my-2">
                <label
                  htmlFor="transactionType"
                  className="block text-gray-800 text-[16px] font-medium mb-1"
                >
                  Type
                </label>
                <select
                  id="transactionType"
                  name="transactionType"
                  value={formData.transactionType}
                  onChange={handleInputChange}
                  className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                  required
                >
                  <option value="--">--</option>
                  <option value="Inflow">Income</option>
                  <option value="Outflow">Expense</option>
                </select>
              </div>
              <div className="my-2">
                <label
                  htmlFor="transactionName"
                  className="block text-gray-800 text-[16px] font-medium mb-1"
                >
                  Transaction Name
                </label>
                <input
                  id="transactionName"
                  name="transactionName"
                  type="text"
                  value={formData.transactionName}
                  onChange={handleInputChange}
                  placeholder="Enter transaction name"
                  className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                  required
                />
              </div>
              <div className="my-2">
                <label
                  htmlFor="transactionAmount"
                  className="block text-gray-800 text-[16px] font-medium mb-1"
                >
                  Amount
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center px-3 text-[18px] font-semibold text-slate-500 bg-slate-200">
                    $
                  </span>
                  <input
                    id="transactionAmount"
                    name="transactionAmount"
                    type="number"
                    value={formData.transactionAmount}
                    onChange={handleInputChange}
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                  />
                </div>
              </div>
              <div className="my-2">
                <label
                  htmlFor="budgetCategory"
                  className="block text-gray-800 text-[16px] font-medium mb-1"
                >
                  Budget Category
                </label>
                <select
                  id="budgetCategory"
                  name="budgetCategory"
                  value={formData.budgetCategory}
                  onChange={handleInputChange}
                  className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                  required
                >
                  <option value="--">--</option>
                  {budget.map((item, index) => (
                  <option value={item.id} >{item.name}</option>
                  ))}
                </select>
              </div>
              <div className="mt mb-4">
                <label
                  htmlFor="transactionDate"
                  className="block text-gray-800 text-[16px] font-medium mb-1"
                >
                  Date for Purchase
                </label>
                <input
                  id="transactionDate"
                  name="transactionDate"
                  type="date"
                  value={formData.transactionDate}
                  onChange={handleInputChange}
                  className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] font-inter"
                  required
                />
              </div>
              <div className="flex justify-end">

                <button type="button" onClick={closeModal} className="uppercase cursor-pointer mx-2 bg-slate-200 text-[14px] duration-1000 ease-in-out text-black font-bold rounded-2xl w-[75%] h-10 my-auto">
                    Close
                </button>

                <button type="submit" className="uppercase cursor-pointer mx-2 bg-sky-500 text-[14px] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[75%] h-10 my-auto">
                    Submit
                </button>

              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default InputTransaction;
