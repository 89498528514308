import React from 'react'
import { MdLogout } from "react-icons/md";


const Navbar = ({ fixed }) => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const handleLogout = () => {
    console.log('Logging out...');
    localStorage.clear()
    window.location.href = '/login';
  };

  return (
    <>
      <nav className={`flex flex-wrap items-center justify-between px-2 py-3 bg-lightBlue-500 mb-3 bg-[#212529] outline outline-bottom-1 outline-slate-700 font-inter ${fixed ? 'fixed top-0 left-0 w-full' : 'relative'}`}>
        <div className="container m-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between ">
            <a className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap no-underline" href="/budget">
              <div class="tracking-tight flex items-center justify-center self-center text-[24px] md:text-[45px] font-[600] text-white"><span className='underline decoration-[3px] lg:decoration-[5px] hover:decoration-sky-600 decoration-sky-400 transition-decoration ease-in-out duration-1000'>Open</span>Budget<button class="mx-3 3 py-1 px-2 my-auto shadow-md no-underline rounded-full bg-sky-400 text-white font-sans font-semibold text-[10px] md:text-sm border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none mr-2">Alpha v1.0</button></div>
            </a>
            <button
              className="text-white ml-auto cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}>
              <svg class="w-7 h-7" fill="#FFFFFF" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
            </button>
          </div>
          <div
            className={
              "flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="list-none flex flex-col justify-end w-full px-10">
              <li className="nav-item">
                <a className="px-3 py-2 flex items-center text-[18px] no-underline font-medium leading-snug text-white hover:text-slate-100 hover:translate-x-1 duration-1000 ease-in-out" href="/">
                  <i className="fab fa-twitter text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Monthly Budget</span>
                </a>
              </li>
              <hr class="w-full h-px my-4 bg-deepskyblue-100 "></hr>
              <li className="nav-item">
                <a className="px-3 py-2 flex items-center text-[18px] no-underline font-medium leading-snug text-white hover:text-slate-100 hover:translate-x-1 duration-1000 ease-in-out" href="/Transactions">
                  <i className="fab fa-twitter text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Transaction History</span>
                </a>
              </li>
              <hr class="w-full h-px my-4 bg-deepskyblue-100 "></hr>
              <li className="nav-item">
                <a className="px-3 py-2 flex items-center text-[18px] no-underline font-medium leading-snug text-white hover:text-slate-100 hover:translate-x-1 duration-1000 ease-in-out" href="/settings">
                  <i className="fab fa-twitter text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Budget Settings</span>
                </a>
              </li>
              <hr class="w-full h-px my-4 bg-deepskyblue-100 "></hr>
              <li className="nav-item">
                <a className="px-3 py-2 flex items-center text-[18px] no-underline font-medium leading-snug text-white hover:text-slate-100 hover:translate-x-1 duration-1000 ease-in-out" href="/account">
                  <i className="fab fa-twitter text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Account Settings</span>
                </a>
              </li>
              <hr class="w-full h-px my-4 bg-deepskyblue-100 "></hr>

              <li className="nav-item">
                <a
                  className="cursor-pointer px-3 py-2 flex items-center text-[18px] no-underline font-medium leading-snug text-slate-300 hover:text-slate-400 hover:translate-x-1 duration-1000 ease-in-out"
                  onClick={handleLogout}  // Call the handleLogout function on click
                >
                  <i className="fab fa-twitter text-lg leading-lg opacity-75"></i>
                  <span className="ml-2">Logout <MdLogout className='px-3 align-middle w-6 py-auto' /></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar