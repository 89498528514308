import React, { useState, useEffect } from 'react';

const BudgetCategoryModal = ({ budget, showModal, closeModal, dataRefresh }) => {
  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }
  
  const [popupError, setPopupError] = useState(false);
  const authToken = localStorage.getItem('authToken');
  console.log(budget)
  

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [formData, setFormData] = useState({
    budgetItemName: '',
    budgetItemType: 'Income',
    budgetAmount: '',
  });


  useEffect(() => {
    // Set formData values from budget when it is provided
    if (budget) {
      setFormData({
        budgetItemName: budget.name || '',
        budgetItemType: budget.budget_type || 'Income',
        budgetAmount: budget.budget_estimate ? budget.budget_estimate.toString() : ''
      });
    }
  }, [showModal]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'budgetAmount' && parseFloat(value) === 0 || value === '') {
      // Set popupError to true
      setPopupError(true);
    } else {
      // Reset popupError if budgetAmount is not 0
      setPopupError(false);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const apiRequestData = {
    name: formData.budgetItemName,
    budget_type: formData.budgetItemType,
    budget_estimate: parseFloat(formData.budgetAmount),
    };

    try {
      // Make the API post request for adding a new budget category
      const response = await fetch(`${baseUrl}/api/add-budget`, {
            method: 'POST',
            headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(apiRequestData),
        });

      // Check if the request was successful
      if (response.ok) {
        console.log('API request successful:', apiRequestData);
        // Clear the form data or perform any other necessary actions
        setFormData({
          budgetItemName: '',
          budgetItemType: 'Income',
          budgetAmount: '',
        });
        // Close the modal
        closeModal();
        dataRefresh();
      } else {
        // Handle the case where the API request was not successful
        console.error('API request failed:', response.statusText);
      }
    } catch (error) {
      // Handle any network-related errors
      console.error('Error making API request:', error);
    }
  };

  const handleFormEdit = async (event) => {
    event.preventDefault();

    const apiRequestData = {
    action: 'Edit',
    id: budget.id,
    name: formData.budgetItemName,
    budget_type: formData.budgetItemType,
    budget_estimate: parseFloat(formData.budgetAmount),
    };

    try {
      // Make the API post request for adding a new budget category
      const response = await fetch(`${baseUrl}/api/edit-budget`, {
            method: 'POST',
            headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(apiRequestData),
        });

      // Check if the request was successful
      if (response.ok) {
        console.log('API request successful:', apiRequestData);
        // Clear the form data or perform any other necessary actions
        setFormData({
          budgetItemName: '',
          budgetItemType: 'Income',
          budgetAmount: '',
        });
        // Close the modal
        closeModal();
        dataRefresh();
      } else {
        // Handle the case where the API request was not successful
        console.error('API request failed:', response.statusText);
      }
    } catch (error) {
      // Handle any network-related errors
      console.error('Error making API request:', error);
    }
  };

  return (
    <>
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="bg-slate-800 absolute inset-0 bg-gray-800 opacity-75"
            onClick={closeModal}
          ></div>
          <div className="bg-white p-8 rounded-lg z-50 shadow-lg sm:w-[500px] !mx-2">
            <h3 className="text-4xl font-semibold mb-6">Add New Budget Category</h3>
            <form className="card-body" onSubmit={budget ? handleFormEdit : handleFormSubmit}>
              {/* ... (existing form fields) */}
              <div className="mb-6">
                <label
                  htmlFor="budgetItemName"
                  className="block text-gray-800 text-lg font-semibold mb-2"
                >
                  Name
                </label>
                <input
                  id="budgetItemName"
                  name="budgetItemName"
                  type="text"
                  value={formData.budgetItemName}
                  onChange={handleInputChange}
                  placeholder="Enter budget item name"
                  className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-xl"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="budgetItemType"
                  className="block text-gray-800 text-lg font-semibold mb-2"
                >
                  Type
                </label>
                <select
                  id="budgetItemType"
                  name="budgetItemType"
                  value={formData.budgetItemType}
                  onChange={handleInputChange}
                  className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-xl"
                  required
                >
                  <option value="Income" selected>Income</option>
                  <option value="Expense">Expense</option>
                </select>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="budgetAmount"
                  className="block text-gray-800 text-lg font-semibold mb-2"
                >
                  Budget Amount
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200">
                    $
                  </span>
                  <input
                    id="budgetAmount"
                    name="budgetAmount"
                    type="number"
                    value={formData.budgetAmount}
                    onChange={handleInputChange}
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-xl"
                    required
                  />
                </div>
              </div>
              {popupError && 
                <div className="bg-[#ffcbc7] text-center border border-red-400 text-red-700 px-4 py-3 rounded relative text-[14px] md:text-[18px] mb-4" role="alert">
                  <strong className="font-bold mx-1 ">Error:</strong>
                  <span className="block inline mx-1 ">Budget Amount Cannot Be Zero</span>
                </div>
              }
              <div className="flex justify-end">
                <button type="button" onClick={closeModal} className="uppercase cursor-pointer mx-2 bg-slate-300 text-[14px] hover:bg-slate-300 hover:scale-[101%] duration-1000 ease-in-out text-black font-bold rounded-2xl w-[75%] h-10 my-auto">
                    Close
                </button>

                <button type="submit" disabled={popupError} className={`uppercase cursor-pointer mx-2 ${popupError ? 'bg-sky-900' : 'bg-sky-500'} text-[14px] hover:bg-sky-600 hover:scale-[101%] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[75%] h-10 my-auto`}>
                    Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default BudgetCategoryModal;
