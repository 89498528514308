import Navbar from "../components/Navbar";
import DateSelector from "../components/DateSelector";
import BudgetCategory from "../components/BudgetCategory";
import Transaction from "../components/Transaction";
import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useState, useEffect, useRef, useLayoutEffect  } from 'react'
import { FaPlus } from "react-icons/fa";
import InputTransaction from "../components/InputTransaction";
import { format, parseISO } from 'date-fns';
import $ from 'jquery';
import BlankCard from "../components/BlankCard";
import BlankTransactionCard from "../components/BlankTransactionCard"
import Loading from "../components/Loading";
import { renderToString } from 'react-dom/server';



const TransactionView = () => {

  


  const [budget, setBudget] = useState([]);
  const [BudgetCategories, setBudgetCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false); 
  const [dataChange, setDataChange] = useState(false);  
  const [selectedTransaction, setSelectedTransaction] = useState(null);




  const getMonth = () => {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    return month;
  };

  const getYear = () => {
    const today = new Date();
    const year = today.getFullYear();
    return year;
  };

  const [month, setMonth] = useState(getMonth());
  const [year, setYear] = useState(getYear());

  function chooseMonth(month) {
    setMonth(month)
    setLoading(true)
  }

  function chooseYear(year) {
    setYear(year)
    setLoading(true)
  }

  const handleTransactionDelete = () => {
    setLoading(true)
    setDataChange(true);
  };

  useEffect(() => {

    // Get the url from the env file
    const env = process.env.REACT_APP_PROD_ENV
    console.log(env)

    let baseUrl

    if(env === "production"){
      baseUrl = process.env.REACT_APP_PROD_URL
      console.log(baseUrl)
    }
    else {
      baseUrl = process.env.REACT_APP_DEV_URL
      console.log(baseUrl)
    }

    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem('authToken');

        console.log(authToken)
  
        // Fetch data from the API
        const response = await fetch(`${baseUrl}/api/get-transactions/${year}/${month}`, {
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        });
  
        const response2 = await fetch(`${baseUrl}/api/get-budget/${year}/${month}`, {
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        });
  
        const jsonData = await response.json();
        const jsonData2 = await response2.json();

        //console.log(response)
        //console.log(jsonData2)
  
        setBudget(jsonData);
        setBudgetCategories(jsonData2);
        setLoading(false);
        setDataChange(false);
  
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [month, year, showModal, dataChange]);
  

  

  function sortByDate(data) {
    // Clone the array to avoid modifying the original array
    const sortedData = [...data];
  
    // Sort the array based on the 'date' property
    sortedData.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      // If dates are equal, then sort by id
        if (dateA - dateB === 0) {
          return b.id - a.id;
        }
  
      return dateB - dateA;
    });
    return sortedData;
  }

  function groupTransactionsByDate(data) {
    // Create an object to store transactions by date
    const groupedTransactions = {};
  
    // Iterate through the data and group transactions by date
    data.forEach(transaction => {
      const date = transaction.date;
  
      // If the date is not a key in the groupedTransactions object, create an array for it
      if (!groupedTransactions[date]) {
        groupedTransactions[date] = [];
      }
  
      // Add the transaction to the corresponding date group
      groupedTransactions[date].push(transaction);
    });

  
    return groupedTransactions;
  }

  const formatDate = dateString => {
    const date = new Date(dateString += "T00:00:00");
    const formattedDate = format(date, 'MMM d, yyyy');
  
    return formattedDate;
  };


  const sortedTransactions = sortByDate(budget);
  const rows = [];
  const groupedTransactions = groupTransactionsByDate(sortedTransactions);
  

  const handleEditClick = (transaction) => {
    // Set the selected transaction and show the modal for editing
    setSelectedTransaction(transaction);
    setShowModal(true);
  };

  // Iterate through the grouped transactions
  for (const date in groupedTransactions) {
    if (groupedTransactions.hasOwnProperty(date)) {
      // Render a header for each date
      const formattedDate = formatDate(date);

      rows.push(<p className="text-slate-400 pl-2 text-[12pt] text-medium" key={date}>{formattedDate}</p>);

      // Render each transaction in the group
      groupedTransactions[date].forEach(transaction => {
        rows.push(<Transaction key={transaction.id} transaction_details={transaction} onDeleteSuccess={handleTransactionDelete} onCardClick={handleEditClick} />);
      });
    }
  }

  const handlePlusClick = () => {
    setShowModal(true);
  };

  const hasFourBudgetItems = BudgetCategories.length >= 4;
  const hasExpenseItems = budget.some(item => item.type === 'Outflow');
  const hasIncomeItems = budget.some(item => item.type === 'Inflow');


  const [scrollPosition, setScrollPosition] = useState(0);
  const buttonRef = useRef();

  useEffect(() => {

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  // Calculate whether to shift the button based on the scroll position
  const shouldShiftButton = scrollPosition + window.innerHeight >= document.documentElement.scrollHeight - 50;


  return (
    <div className="absolute bg-[#33363B] w-full min-h-screen text-left text-5xl text-black font-inter">


      <Navbar />
      <Header page_name={"TRANSACTIONS"}/>

     
      {hasFourBudgetItems &&
      <div
        id="button"
        className={`${loading && 'hidden'} fixed ${
          shouldShiftButton ? 'translate-y-[-7rem] md:translate-y-[-7rem] transition bottom-[1rem] ease-in-out duration-[1400ms]' : 'translate-y-0 transition bottom-[1rem] ease-in-out duration-[1400ms]'
        } right-[-1rem] pr-8 w-fit md:pr-20 top-auto left-auto overscroll-none`}
        ref={buttonRef}>  
        <div onClick={handlePlusClick} className="flex items-center shadow-xl hover:scale-105 justify-center cursor-pointer transform opacity-90 px-5 py-4 align-middle hover:opacity-100 bg-sky-600 rounded-full transition-transform duration-1000 ease-in-out">
          <FaPlus class="text-white h-4 w-4 text-[12pt] text-left mr-3 shadow-lg duration-1000 ease-in-out" /> 
          <span className="mr-3 text-white font-bold text-sm align middle">CREATE</span>
        </div>
      </div>}
      
      {showModal && 
        <InputTransaction
        budget={BudgetCategories}
        transaction_details={selectedTransaction}
        showModal={showModal}
        closeModal={() => {
          setShowModal(false);
          setLoading(true);
          setSelectedTransaction(null);
          setDataChange(true);
        }}/>
      } {/* Pass showModal and closeModal */}

      
      <DateSelector chooseMonth={chooseMonth} chooseYear={chooseYear}/>
  


      {!loading ? (
        hasFourBudgetItems ? (
          hasExpenseItems || hasIncomeItems ? (
            <div className="mx-3 px-2 !mb-[200px] py-1 my-1 md:mx-10 md:px-5 md:py-5 md:my-5 bg-[#f1f1f1] rounded-[10px]">
              {rows}
            </div>
          ) : (
            <BlankTransactionCard />
          )
        ) : (
          <BlankCard height={250} message="You have to create at least four budget items before entering transactions" link="/settings" linkName="budget settings" />
        )
      ) : (
        <Loading />
      )}
      
      <div className="w-[100%] mt-[7rem]">
        <Footer />
      </div>
      

    </div>
  );
};

export default TransactionView;
