import React, { useState, useEffect } from 'react';

const InputTransaction = ({ showModal, closeModal, budget }) => {

    // Get the url from the env file
  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }
  
  const [loading, setLoading] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState({
    BANK_FEES: '--',
    ENTERTAINMENT: '--',
    FOOD_AND_DRINK: '--',
    GENERAL_MERCHANDISE: '--',
    GENERAL_SERVICES: '--',
    GOVERNMENT_AND_NON_PROFIT: '--',
    HOME_IMPROVEMENT: '--',
    INCOME: '--',
    LOAN_PAYMENTS: '--',
    MEDICAL: '--',
    PERSONAL_CARE: '--',
    RENT_AND_UTILITIES: '--',
    TRANSFER_IN: '--',
    TRANSFER_OUT: '--',
    TRANSPORTATION: '--',
    TRAVEL: '--',
    // Add more categories if needed
  });



  const handleCategoryChange = (category, value) => {
    setSelectedCategories((prevCategories) => ({
      ...prevCategories,
      [category]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const authToken = localStorage.getItem('authToken');
      // Your server endpoint where you want to send the data
      const endpoint = `${baseUrl}/api/create-preferences`; // Replace with your actual endpoint

      // Prepare the data to send
      const formData = {
        categories: selectedCategories,
        // Include any other data you want to send
      };

      // Send a POST request
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      // Handle the response (you can customize this based on your server's response format)
      if (response.ok) {
        setLoading(false)
        console.log('Data sent successfully');
        closeModal()
      } else {
        setLoading(false)
        console.error('Error sending data to the server');
      }
    } catch (error) {
      setLoading(false)
      console.error('Error:', error);
    }
  };

  const getPreferences = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      // Your server endpoint where you want to send the data
      const endpoint = `${baseUrl}/api/get-preferences`; // Replace with your actual endpoint

      // Send a POST request
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
        }
      });

      const jsonData = await response.json();
      
      // Handle the response (you can customize this based on your server's response format)
      if (response.ok) {
        console.log('Data received successfully');
        
        if(jsonData[0]?.plaid_match.categories != undefined){
            setSelectedCategories(jsonData[0]?.plaid_match.categories);
        } else{
            console.error('Error:', error);
        }

      } else {
        console.error('Error sending data to the server');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  useEffect(() => {
    getPreferences()
  }, []);
  

  useEffect(() => {
    console.log('Selected Categories:', selectedCategories);
  }, [selectedCategories]);


  return (
    <>
      {showModal && (
        <div className="font-inter fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="bg-slate-800 absolute inset-0 bg-gray-800 opacity-75"
            onClick={closeModal}
          ></div>
          <div className="bg-white flex flex-col relative px-8 py-4 rounded-lg z-50 shadow-lg sm:w-[500px] !mx-2">
            <h3 className="text-[14pt] mb-6 uppercase font-bold">
              Plaid Match
            </h3>
            {!loading ? (
            <form onSubmit={(e) => {e.preventDefault();handleSubmit();}}>
                <div className="h-[500px] px-4 overflow-y-scroll scrollbar-thin scrollbar-track-slate-50 scrollbar-thumb-sky-500 scrollbar-rounded-xl">
                {/* Bank Fees Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeBankFees"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Bank Fees
                    </label>
                    <select
                    id="budgetCategoryBankFees"
                    name="budgetCategoryBankFees"
                    value={selectedCategories.BANK_FEES}
                    onChange={(e) =>
                        handleCategoryChange('BANK_FEES', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Entertainment Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeEntertainment"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Entertainment
                    </label>
                    <select
                    id="budgetCategoryEntertainment"
                    name="budgetCategoryEntertainment"
                    value={selectedCategories.ENTERTAINMENT}
                    onChange={(e) =>
                        handleCategoryChange('ENTERTAINMENT', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Food & Drink Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeFoodDrink"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Food & Drink
                    </label>
                    <select
                    id="budgetCategoryFoodDrink"
                    name="budgetCategoryFoodDrink"
                    value={selectedCategories.FOOD_AND_DRINK}
                    onChange={(e) =>
                        handleCategoryChange('FOOD_AND_DRINK', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* General Merchandise Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeGeneralMerchandise"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    General Merchandise
                    </label>
                    <select
                    id="budgetCategoryGeneralMerchandise"
                    name="budgetCategoryGeneralMerchandise"
                    value={selectedCategories.GENERAL_MERCHANDISE}
                    onChange={(e) =>
                        handleCategoryChange(
                        'GENERAL_MERCHANDISE',
                        e.target.value
                        )
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* General Services Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeGeneralServices"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    General Services
                    </label>
                    <select
                    id="budgetCategoryGeneralServices"
                    name="budgetCategoryGeneralServices"
                    value={selectedCategories.GENERAL_SERVICES}
                    onChange={(e) =>
                        handleCategoryChange('GENERAL_SERVICES', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Government & Non-Profit Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeGovernmentNonProfit"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Government & Non-Profit
                    </label>
                    <select
                    id="budgetCategoryGovernmentNonProfit"
                    name="budgetCategoryGovernmentNonProfit"
                    value={selectedCategories.GOVERNMENT_AND_NON_PROFIT}
                    onChange={(e) =>
                        handleCategoryChange(
                        'GOVERNMENT_AND_NON_PROFIT',
                        e.target.value
                        )
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Home Improvement Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeHomeImprovement"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Home Improvement
                    </label>
                    <select
                    id="budgetCategoryHomeImprovement"
                    name="budgetCategoryHomeImprovement"
                    value={selectedCategories.HOME_IMPROVEMENT}
                    onChange={(e) =>
                        handleCategoryChange('HOME_IMPROVEMENT', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Income Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeIncome"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Income
                    </label>
                    <select
                    id="budgetCategoryIncome"
                    name="budgetCategoryIncome"
                    value={selectedCategories.INCOME}
                    onChange={(e) =>
                        handleCategoryChange('INCOME', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Loan Payments Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeLoanPayments"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Loan Payments
                    </label>
                    <select
                    id="budgetCategoryLoanPayments"
                    name="budgetCategoryLoanPayments"
                    value={selectedCategories.LOAN_PAYMENTS}
                    onChange={(e) =>
                        handleCategoryChange('LOAN_PAYMENTS', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Medical Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeMedical"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Medical
                    </label>
                    <select
                    id="budgetCategoryMedical"
                    name="budgetCategoryMedical"
                    value={selectedCategories.MEDICAL}
                    onChange={(e) =>
                        handleCategoryChange('MEDICAL', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Personal Care Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypePersonalCare"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Personal Care
                    </label>
                    <select
                    id="budgetCategoryPersonalCare"
                    name="budgetCategoryPersonalCare"
                    value={selectedCategories.PERSONAL_CARE}
                    onChange={(e) =>
                        handleCategoryChange('PERSONAL_CARE', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Rent & Utilities Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeRentUtilities"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Rent & Utilities
                    </label>
                    <select
                    id="budgetCategoryRentUtilities"
                    name="budgetCategoryRentUtilities"
                    value={selectedCategories.RENT_AND_UTILITIES}
                    onChange={(e) =>
                        handleCategoryChange('RENT_AND_UTILITIES', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Transfer In Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeTransferIn"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Transfer In
                    </label>
                    <select
                    id="budgetCategoryTransferIn"
                    name="budgetCategoryTransferIn"
                    value={selectedCategories.TRANSFER_IN}
                    onChange={(e) =>
                        handleCategoryChange('TRANSFER_IN', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Transfer Out Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeTransferOut"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Transfer Out
                    </label>
                    <select
                    id="budgetCategoryTransferOut"
                    name="budgetCategoryTransferOut"
                    value={selectedCategories.TRANSFER_OUT}
                    onChange={(e) =>
                        handleCategoryChange('TRANSFER_OUT', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Transportation Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeTransportation"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Transportation
                    </label>
                    <select
                    id="budgetCategoryTransportation"
                    name="budgetCategoryTransportation"
                    value={selectedCategories.TRANSPORTATION}
                    onChange={(e) =>
                        handleCategoryChange('TRANSPORTATION', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Travel Category */}
                <div className="my-2">
                    <label
                    htmlFor="transactionTypeTravel"
                    className="block text-gray-800 text-[16px] font-medium mb-1"
                    >
                    Travel
                    </label>
                    <select
                    id="budgetCategoryTravel"
                    name="budgetCategoryTravel"
                    value={selectedCategories.TRAVEL}
                    onChange={(e) =>
                        handleCategoryChange('TRAVEL', e.target.value)
                    }
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px]"
                    required
                    >
                    <option value="--">--</option>
                    {budget.map((item, index) => (
                        <option key={index} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                    </select>
                </div>

                {/* Repeat for other categories */}
                </div>
                <div className="flex justify-end my-4">
                    <button type="button" onClick={closeModal} className="cursor-pointer mx-2 bg-slate-300 text-[18px] duration-1000 ease-in-out text-black font-bold rounded-2xl w-[75%] h-10 my-auto">
                        Close
                    </button>
                    <button type="submit" className="cursor-pointer mx-2 bg-sky-500 text-[18px] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[75%] h-10 my-auto">
                        Save Preferences
                    </button>
                </div> 
            </form>
            ) : (
              <div className="flex flex-col items-center justify-center h-[65vh]" role="status text-center">
                    <svg aria-hidden="true" className="w-16 h-16 text-slate-300 animate-spin fill-sky-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <h1 className="text-xl px-20 py-5 text-center text-slate-700">Tallying up the impulse purchases <span className='text-sky-400'>...</span></h1>
              </div>
            )}       
          </div>
        </div>
      )}
    </>
  );
};

export default InputTransaction;
