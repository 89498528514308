import React, { useState } from 'react';
import { FaCar } from "react-icons/fa";

const TransportationMessage = ({ handle, back }) => {

  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }

  
  const authToken = localStorage.getItem('authToken');
  const [carPayments, setCarPayments] = useState(0);
  const [insurance, setInsurance] = useState(0);
  const [monthlyGas, setMonthlyGas] = useState(0);
  const [publicTransportation, setPublicTransportation] = useState(0);
  const [uberRideshare, setUberRideshare] = useState(0);
  const [maintenance,setMaintenance] = useState(0);

  const [confirmed, setConfirmed] = useState(false);
  const [monthlyTransportationCalc, setMonthlyTransportationCalc] = useState(0);
  const [postInformation, setPostInformation] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Calculate total monthly transportation expenses
    let monthlyTransportationExpenses = 0;

    // Car Payments
    monthlyTransportationExpenses += carPayments || 0;

    // Maintenance
    monthlyTransportationExpenses += maintenance || 0;

    // Insurance
    monthlyTransportationExpenses += insurance || 0;

    // Monthly Gas
    monthlyTransportationExpenses += monthlyGas || 0;

    // Public Transportation
    monthlyTransportationExpenses += publicTransportation || 0;

    // Uber/Rideshare
    monthlyTransportationExpenses += uberRideshare || 0;

    // Round to two decimal places
    monthlyTransportationExpenses = monthlyTransportationExpenses.toFixed(2);

    setMonthlyTransportationCalc(monthlyTransportationExpenses);

    // Prepare data for API post request
    const postData = [{
      name: 'Transportation Expenses',
      budget_estimate: monthlyTransportationExpenses,
      budget_type: 'Expense',
      // Add other data you want to send to the API
    }];

    setPostInformation(postData);
    setConfirmed(true);
  };

  const handleConfirmation = async () => {
    if (monthlyTransportationCalc == 0) {
      handle();
    } else {
      try {
        // Replace with your actual API endpoint
        const response = await fetch(`${baseUrl}/api/intro-budget`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postInformation),
        });

        if (response.ok) {
          // Handle success, e.g., show a success message or navigate to the next step
          console.log('API request successful');
          handle();
        } else {
          // Handle error, e.g., show an error message
          console.error('API request failed');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error during API request:', error);
      }
    }
  };

  return (
    <div className='h-full '>
      <div className='flex flex-col items-center justify-center h-full text-center'>
        <h3 className="text-[24px] py-5 md:text-[32px] font-bold text-center mx-8 my-2 md:mx-0 underline-offset-1 underline decoration-sky-400">Transportation Expenses</h3>
        {!confirmed ?
        <form onSubmit={handleFormSubmit} className='pt-4 pb-0 relative w-[90%] md:w-[50%]'>
          <div className='relative flex rounded-xl flex-col h-[500px] px-8 overflow-y-scroll scrollbar-thin scrollbar-track-slate-50 scrollbar-thumb-sky-500 scrollbar-rounded-xl'>
            <div className="my-3">

              <label
                htmlFor="transactionAmount"
                className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2">
                Monthly Car Payments
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                  $
                </span>
                <input
                  id="carPayments"
                  type="number"
                  value={carPayments}
                  onChange={(e) => setCarPayments(parseFloat(e.target.value))}
                  className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                  required
                />
              </div>

            </div>
            <div className="my-3">

              <label
                htmlFor="transactionAmount"
                className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2">
                Yearly Car Maintenance
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                  $
                </span>
                <input
                  id="insurance"
                  type="number"
                  value={maintenance}
                  onChange={(e) => setMaintenance(parseFloat(e.target.value))}
                  className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                  required
                />
              </div>

            </div>
            <div className="my-3">

              <label
                htmlFor="transactionAmount"
                className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2">
                Monthly Insurance
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                  $
                </span>
                <input
                  id="insurance"
                  type="number"
                  value={insurance}
                  onChange={(e) => setInsurance(parseFloat(e.target.value))}
                  className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                  required
                />
              </div>

            </div>

            <div className="my-3">

              <label
                htmlFor="transactionAmount"
                className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2">
                Monthly Gas Costs
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                  $
                </span>
                <input
                  id="monthlyGas"
                  type="number"
                  value={monthlyGas}
                  onChange={(e) => setMonthlyGas(parseFloat(e.target.value))}
                  className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                  required
                />
              </div>
              
            </div>
            <div className="my-3">
              <label
                htmlFor="transactionAmount"
                className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2">
                  Monthly Public Transportation
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                  $
                </span>
                <input
                  id="transportation"
                  name="transportation"
                  type="number"
                  value={publicTransportation}
                  onChange={(e) => setPublicTransportation(parseFloat(e.target.value))}
                  className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                  required
                />
              </div>
            </div>
            <div className="my-3 pb-[3.5rem]">
              <label
                htmlFor="transactionAmount"
                className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2">
                Monthly Uber/Rideshare
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                  $
                </span>
                <input
                  id="uberRideshare"
                  type="number"
                  value={uberRideshare}
                  onChange={(e) => setUberRideshare(parseFloat(e.target.value))}
                  className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                  required
                />
              </div>
            </div>
          </div>

          <div className='absolute blur inset-x-0 bottom-0 h-[8rem] md:h-[6rem] bg-gradient-to-t opacity-80 from-85% from-slate-100 to-transparent'></div>


          <div className='py-2 w-full relative flex justify-center items-center'>
              <button
                type="button"
                onClick={() => { setConfirmed(false); back(); } }
                className="z-10 cursor-pointer bg-slate-300 text-[18px] text-black duration-1000 ease-in-out font-bold rounded-2xl w-[90%] px-2 mx-1 h-10 my-1 flex justify-center items-center"
              >
                Back
              </button>
              <button
                type="submit"
                className="z-10 cursor-pointer bg-sky-500 text-[18px] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[90%] px-2 mx-1 h-10 my-1 flex justify-center items-center"
              >
                Continue
              </button>
          </div>
            
        </form>
          :
          <div className='py-[5%] my-[5%] outline-dashed outline-2 outline-offset-2 outline-slate-300 rounded-lg'>
            <h1 className='text-[18px] md:text-[24px] px-10'>Your Estimated <span className='underline-offset-1 font-bold underline decoration-sky-400'>Average</span> Monthly Income:</h1>
            {/* Add content for confirmation */}
            <FaCar className='text-slate-700 h-[6rem] w-[6rem]' />
            <h1 className='text-[42px] md:text-[48px] text-slate-700 my-1'>$ {monthlyTransportationCalc}</h1>
            <h1 className='text-[18px] md:text-[24px] font-medium py-2 text-slate-700'>Does this appear accurate?</h1>
            <div className='flex md:px-10 py-4'>
              <button onClick={() => setConfirmed(false)} className="cursor-pointer bg-slate-300 text-[16px] md:text-[18px] duration-1000 ease-in-out text-black font-bold rounded-2xl w-[75%] md:w-full h-10 my-1 mx-2">Edit Details</button>
              <button onClick={handleConfirmation} className="cursor-pointer bg-sky-500 text-[16px] md:text-[18px] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[75%] md:w-full h-10 my-1 mx-2">Yes, Go Ahead</button>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default TransportationMessage;
