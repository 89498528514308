import React, { useState } from 'react';
import { GiMoneyStack } from "react-icons/gi";


const SalaryMessage = ({ handle }) => {

  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }
  
  const authToken = localStorage.getItem('authToken');
  const [hasSideHustle, setHasSideHustle] = useState(false);
  const [paycheckAmount, setPaycheckAmount] = useState(0);
  const [tipsOrCommissions, setTipsOrCommissions] = useState(0);
  const [yearlyBonuses, setYearlyBonuses] = useState(0);
  const [sideHustleIncome, setSideHustleIncome] = useState(0);
  const [payPeriod, setPayPeriod] = useState('monthly'); // Added state for pay period
  
  const [confirmed, setConfirmed] = useState(false)
  const [monthlyIncomeCalc, setMonthlyIncomeCalc] = useState(0)
  const [postInformation,setPostInformation] = useState("")


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    
    // Calculate total monthly income based on pay period
    let monthlyIncome;

    if (payPeriod === 'weekly') {
      monthlyIncome = (parseFloat(paycheckAmount) + parseFloat(tipsOrCommissions)) * 52 / 12;

    } else if (payPeriod === 'bi-weekly') {
      monthlyIncome = (parseFloat(paycheckAmount) + parseFloat(tipsOrCommissions)) * 26 / 12;

    } else {
      // Monthly pay period
      monthlyIncome = parseFloat(paycheckAmount) + parseFloat(tipsOrCommissions);
    }


    // Add yearly bonuses and side hustle income
    monthlyIncome += parseFloat(((parseFloat(yearlyBonuses) / 12) + (hasSideHustle ? parseFloat(sideHustleIncome) : 0)));

    monthlyIncome = monthlyIncome.toFixed(2)

    setMonthlyIncomeCalc(monthlyIncome)

    // Prepare data for API post request
    const postData = [{
      name:'Monthly Income',
      budget_estimate: monthlyIncome,
      budget_type: 'Income'
      // Add other data you want to send to the API
    }];


    setPostInformation(postData)
    setConfirmed(true)
    

  }
  
  const handleConfirmation = async () => {

    if (monthlyIncomeCalc == 0.0){
      handle();
    }
    else{
      try {
        // Replace with your actual API endpoint
        const response = await fetch(`${baseUrl}/api/intro-budget`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
            },
          body: JSON.stringify(postInformation),
        });
  
        if (response.ok) {
          // Handle success, e.g., show a success message or navigate to the next step
          console.log('API request successful');
          handle();
        } else {
          // Handle error, e.g., show an error message
          console.error('API request failed');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error during API request:', error);
      }
    }
    
  };

  return (
    <div className='h-full'>
      <div className='flex flex-col items-center justify-center h-full text-center'>
        {!confirmed ? 
        <>
          <form onSubmit={handleFormSubmit} className='pt-4 pb-0 relative w-[90%] md:w-[50%]'>
            <div className='relative flex rounded-xl flex-col h-[500px] px-8 overflow-y-scroll scrollbar-thin scrollbar-track-slate-50 scrollbar-thumb-sky-500 scrollbar-rounded-xl'>
              <h3 className="text-[24px] py-5 md:text-[32px] font-bold text-center mx-8 my-2 md:mx-0 underline-offset-1 underline decoration-sky-400">Income Sources</h3>
              <div className="my-3">
                <label
                  htmlFor="transactionAmount"
                  className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                >
                  Average Paycheck Amount (After Tax)
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                    $
                  </span>
                  <input
                    id="transactionAmount"
                    name="transactionAmount"
                    type="number"
                    value={paycheckAmount}
                    onChange={(e) => setPaycheckAmount(e.target.value)}
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                    required
                  />
                </div>
              </div>
              <div className="my-3">
                <label
                  htmlFor="transactionAmount"
                  className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                >
                  Tips or Commissions (After Tax)
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                    $
                  </span>
                  <input
                    id="transactionAmount"
                    name="transactionAmount"
                    type="number"
                    value={tipsOrCommissions}
                    onChange={(e) => setTipsOrCommissions(e.target.value)}
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                    required
                  />
                </div>
              </div>
              <div className="my-3 md:flex mx-1 md:mx-auto justify-center">
                <div className="flex items-center my-4 md:mx-4">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value="weekly"
                    name="default-radio"
                    checked={payPeriod === 'weekly'}
                    onChange={(e) => setPayPeriod(e.target.value)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-radio-1"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Weekly
                  </label>
                </div>
                <div className="flex items-center my-4 md:mx-4">
                  <input
                    id="default-radio-2"
                    type="radio"
                    value="bi-weekly"
                    name="default-radio"
                    checked={payPeriod === 'bi-weekly'}
                    onChange={(e) => setPayPeriod(e.target.value)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-radio-2"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Bi-weekly
                  </label>
                </div>
                <div className="flex items-center my-4 md:mx-4">
                  <input
                    id="default-radio-3"
                    type="radio"
                    value="monthly"
                    name="pay-period"
                    checked={payPeriod === 'monthly'}
                    onChange={(e) => setPayPeriod(e.target.value)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-radio-3"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Monthly
                  </label>
                </div>
              </div>

              <div className="my-3">
                <label
                  htmlFor="transactionAmount"
                  className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                >
                  Yearly Performance Bonuses
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                    $
                  </span>
                  <input
                    id="transactionAmount"
                    name="transactionAmount"
                    type="number"
                    value={yearlyBonuses}
                    onChange={(e) => setYearlyBonuses(e.target.value)}
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                    required
                  />
                </div>
              </div>

              <div className={`my-3 ${!hasSideHustle ? 'pb-[3.5rem]' : ''}`}>
                <label
                  htmlFor="transactionAmount"
                  className="block text-gray-800 text-[12px] md:text-[14px] font-semibold text-left mb-2"
                >
                  Do you have a side hustle or additional income?
                </label>

                <div className='md:flex mx-auto'>
                  <div className="flex items-center my-4 md:mx-4">
                    <input
                      id="side-hustle-yes"
                      type="radio"
                      value=""
                      name="side-hustle-radio"
                      checked={hasSideHustle}
                      onChange={() => setHasSideHustle(true)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="side-hustle-yes"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="flex items-center my-4 md:mx-4">
                    <input
                      id="side-hustle-no"
                      type="radio"
                      value=""
                      name="side-hustle-radio"
                      checked={!hasSideHustle}
                      onChange={() => setHasSideHustle(false)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="side-hustle-no"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              {hasSideHustle && (
                <div className="my-3 pb-[3.5rem]">
                  <label
                    htmlFor="sideHustleIncome"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    Monthly Side Hustle Income (After Tax)
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                      $
                    </span>
                    <input
                      id="sideHustleIncome"
                      name="sideHustleIncome"
                      type="number"
                      value={sideHustleIncome}
                      onChange={(e) => setSideHustleIncome(e.target.value)}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='absolute blur inset-x-0 bottom-0 h-[8rem] md:h-[6rem] bg-gradient-to-t opacity-80 from-85% from-slate-100 to-transparent'></div>

            
            <div className='py-2 w-full relative'>
              <button
                type="submit"
                className="cursor-pointer bg-sky-500 text-[18px] hover:bg-sky-600 hover:scale-[101%] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[75%] md:w-full h-10 my-1"
              >
                Continue
              </button>
            </div>
          </form>
        </>
        :
        <div className='py-[5%] my-[5%] outline-dashed outline-2 outline-offset-2 outline-slate-300 rounded-lg'>
          <h1 className='text-[18px] md:text-[24px] px-10'>Your Estimated <span className='underline-offset-1 font-bold underline decoration-sky-400'>Average</span> Monthly Income:</h1>
          <GiMoneyStack className='text-slate-700 h-[6rem] w-[6rem]'/>
          <h1 className='text-[42px] md:text-[48px] text-slate-700 my-1'>$ { monthlyIncomeCalc }</h1>
          <h1 className='text-[18px] md:text-[24px] font-medium py-2 text-slate-700'>Does this appear accurate?</h1>
          <div className='flex md:px-10 py-4'>
            <button onClick={() => setConfirmed(false)} className="cursor-pointer bg-slate-300 text-[16px] md:text-[18px] duration-1000 ease-in-out text-black font-bold rounded-2xl w-[75%] md:w-full h-10 my-1 mx-2">Edit Details</button>
            <button onClick={handleConfirmation} className="cursor-pointer bg-sky-500 text-[16px] md:text-[18px] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[75%] md:w-full h-10 my-1 mx-2">Yes, Go Ahead</button>
          </div>
        </div>
          }
      </div>
    </div>
  );
}

export default SalaryMessage;
