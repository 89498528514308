import React, { useRef, useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import moment from 'moment';
Chart.register(ArcElement, [Tooltip]);

const PieChart = ({ data: inputData }) => {
  inputData = inputData.filter(item => item.budget_type === 'Expense');
  const totalBudget = inputData.reduce((total, item) => total + item.budget_actual, 0);
  const formattedTotalBudget = totalBudget.toLocaleString(undefined, { maximumFractionDigits: 0 });



  const chartRef = useRef(null);

  const data = {
    labels: inputData.map(item => item.name),
    datasets: [
      {
        data: inputData.map(item => item.budget_actual),
        backgroundColor: [
          '#e2f9fe',
          '#b1effe',
          '#80e5ff',
          '#4fdcff',
          '#1ed2ff',
          '#00bdec',
          '#0096bb',
        ],
        borderColor: 'white',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 2,
    cutout: '70%',
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = context.parsed;
            return `Amount: $${value.toFixed(2)}`;
          },
        },
      },
    },
  };

  
  let textCenter = {
    id: 'textCenter',
    afterDatasetDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      
      const inputData = data.datasets[0].data;
      const total = Object.values(inputData).reduce((acc, value) => acc + value, 0);
      const formattedTotalBudget = total.toLocaleString(undefined, { maximumFractionDigits: 0 });


      ctx.restore()
      ctx.font = 'bold 30px Inter, sans-serif'; // Set the font to Arial
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('$ '+formattedTotalBudget, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y-10);
      ctx.font = 'bold 15px Inter, sans-serif'; // Set the font to Arial
      ctx.fillText('EXPENSE TOTAL', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y+20);
      ctx.save();
      

    },
  };


  return (
    <div className="w-[90%] md:w-[50%] h-[300px] md:h-[300px] mx-auto">
      <Doughnut
        ref={chartRef}
        data={data}
        options={options}
        plugins={[textCenter]}
      />
    </div>
  );
};

export default PieChart;
