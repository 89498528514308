import React from 'react'

const SavingsMessage = ({handle}) => {
  return (
    <div className='flex flex-col items-center justify-center h-full text-center'>
      <div className='pb-5'>
            <h3 className="text-[32px] font-bold my-8 mx-8 md:mx-0">Savings</h3>
            <h3 className="md:mx-[10%] text-[18px] md:text-[24px] font-medium my-6">401K Contributions</h3>
            <h3 className="md:mx-[10%] text-[18px] md:text-[24px] font-medium my-6">Roth IRA Contributions</h3>
            <h3 className="md:mx-[10%] text-[18px] md:text-[24px] font-medium my-6">Monthly Gas</h3>
            <h3 className="md:mx-[10%] text-[18px] md:text-[24px] font-medium my-6">Public transportation costs</h3>
            <h3 className="md:mx-[10%] text-[18px] md:text-[24px] font-medium my-6">Ubers or rideshare</h3>
        </div>

        <button type="submit" onClick={handle} className="cursor-pointer absolute bottom-[7rem] bg-sky-500 text-[18px] hover:bg-sky-600 hover:scale-[101%] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[75%] md:w-[25%] h-10 my-6">
            Get Started
        </button>
    </div>
  )
}

export default SavingsMessage