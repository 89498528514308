import React, { useState } from 'react';
import Mockup from '../images/Mockup.png'
import Mockup2 from '../images/Mockup2.png'
import Mockup4 from '../images/Mockup4.png'
import { AiFillAlert } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';



const LandingPage = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleEmail = () =>{

    navigate(`/signup/${email}`);
  }


  return (
    <div className="relative flex flex-col min-h-screen bg-[#212529] w-full text-left text-5xl text-black font-inter">
        {/* mobile */}
        <div className='flex flex-col md:hidden'>
            <div className="w-full h-full bg-slate-300 rounded-lg my-4 flex items-center justify-center ">
                    <img
                        src={Mockup4}
                        alt="Your Image Description"
                        className="mx-auto w-[18rem] h-auto"
                    />
            </div>

            {/* Hero Section */}
            <div className=' z-10 flex flex-col pt-2 w-full'>
                <div className='px-3 pb-12'>
                    <h2 className='text-center text-[36px] tracking-tighter 2xl:text-[80px] text-slate-50 font-[700px] my-2'><span className='underline decoration-sky-500'>Open</span>Budget</h2>
                    <p className='my-4'>
                        <p className='text-center text-slate-200 my-0 text-[16px] w-[80%] mx-auto'>Dead simple budgets designed</p>
                        <p className='text-center text-slate-200 my-0 text-[16px] w-[80%] mx-auto'>to <span className='font-bold'>save you money</span></p>
                    </p>

                    <div className='mx-auto my-2 h-12 w-[85%] bg-slate-50 rounded-full flex flex-row'>
                        <input type="email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="text-[16px] w-full h-10 ml-4 mr-2 my-auto outline-none text-gray-800" placeholder="Luke@easybudget.com"/>
                        <button onClick={handleEmail} className="cursor-pointer bg-sky-400 h-10 font-black uppercase my-auto w-[40%] text-[14px] rounded-full mr-2 text-white">Join</button>
                    </div>
                    <div className='mx-auto my-1 p-0 w-[80%] rounded-full flex flex-row'>
                        <p className='text-slate-200 w-full m-0 p-0 text-[14px]'>Already a finance genius? <a className='no-underline' href='/login'><span className='text-sky-400'>Sign in</span></a></p>
                    </div>
                    <div className=''>
                        <ul className='text-white mx-auto w-fit list-none my-8 px-0 text-[18px] xl:text-[24px]'>
                                <li className='my-3 mr-4'><FaCheck className='text-sky-400 w-4 h-4 mx-4 items-center'/>Simple Design</li>
                                <li className='my-3 mr-4'><FaCheck className='text-sky-400 w-4 h-4 mx-4 items-center'/>Free Forever</li>
                                <li className='my-3 mr-4'><FaCheck className='text-sky-400 w-4 h-4 mx-4 items-center'/>Open Source</li>
                        </ul>
                    </div>

                </div>
            </div>
            
            
            <div className='w-full bg-slate-600 text-white text-[10px] h-[30px] font-semibold uppercase bottom-0 fixed'>
                <ul className="flex mx-auto px-0">
                    <li className='inline mx-auto'>Terms & Conditions</li>
                    <li className='inline mx-auto'>Privacy Policy</li>
                    <li className='inline mx-auto'>Contact us</li>
                </ul>
            </div>
        </div>
        {/* desktop */}
        <div className='relative hidden md:flex flex-col'>
                {/* Hero Section */}
                <div className='h-screen w-[50%] flex flex-col items-center justify-center z-10'>
                    <div className='w-[65%]'>
                        <h2 className='text-[56px] tracking-tighter 2xl:text-[80px] text-slate-50 font-[700px] my-4'><span className='underline decoration-sky-500'>Open</span>Budget</h2>
                        <p className='text-slate-200 my-0 text-[22px] 2xl:text-[36px]'>Dead simple budgets designed</p>
                        <p className='text-slate-200 my-0 text-[22px] 2xl:text-[36px]'>to <span className='font-bold'>save you money</span></p>
                        <div className='my-4 h-12 w-full bg-slate-50 rounded-full flex flex-row'>
                            <input type="email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="text-[18px] xl:text-[20px] w-full h-10 ml-4 mr-2 my-auto outline-none text-gray-800" placeholder="Luke@easybudget.com"/>
                            <button onClick={handleEmail} className="cursor-pointer bg-sky-400 h-10 font-black uppercase my-auto w-[40%] xl:w-[35%] rounded-full mr-2 text-white">Join</button>
                        </div>
                        <p className='text-slate-200 my-2 2xl:my-4 text-[14px] 2xl:text-[18px]'>Already a finance genius? <a className='no-underline' href='/login'><span className='text-sky-400'>Sign in</span></a></p>
                        <ul className='text-white list-none my-10 text-[18px] xl:text-[24px]'>
                            <li className='my-4'><FaCheck className='text-sky-400 w-4 h-4 mx-4'/>Simple Design</li>
                            <li className='my-4'><FaCheck className='text-sky-400 w-4 h-4 mx-4'/>Free Forever</li>
                            <li className='my-4'><FaCheck className='text-sky-400 w-4 h-4 mx-4'/>Open Source</li>
                        </ul>
                    </div>


                </div>
                
                <div className="w-full fixed bottom-0 flex flex-col z-50">
                        
                        <div className='w-full bg-slate-600 text-white text-[12px] 2xl:text-[16px]'>
                            <ul >
                                <li className='inline mx-4'>Terms & Conditions</li>
                                <li className='inline mx-4'>Privacy Policy</li>
                                <li className='inline mx-4'>Contact us</li>
                            </ul>
                        </div>
                </div>
                    
                <div className="fixed w-[50%] h-full right-0 transform flex items-center justify-center z-0">
                    <img
                    src={Mockup4}
                    alt="Your Image Description"
                    className="w-[500px] lg:w-[600px] xl:w-[650px] 2xl:w-[600px] 3xl:w-[650px] pr-[2%] 2xl:pr-[10%] my-auto"
                    />
                </div>

        </div>


    </div>
  );
};

export default LandingPage;
