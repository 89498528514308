import React, { useState } from 'react';
import { GiHouse } from "react-icons/gi";


const HousingMessage = ({ handle, back }) => {

  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }
  
  const authToken = localStorage.getItem('authToken');
  const [mortgageOrRentPayment, setMortgageOrRentPayment] = useState(0);
  const [monthlyUtilities, setMonthlyUtilities] = useState(0);
  const [hasHOA, setHasHOA] = useState(false);
  const [hoaFees, setHoaFees] = useState(0);
  const [insurance, setInsurance] = useState(0);
  const [propertyTaxes, setPropertyTaxes] = useState(0);
  const [homeCare, setHomeCare] = useState(0);

  const [confirmed, setConfirmed] = useState(false)
  const [monthlyHousingCalc, setMonthlyHousingCalc] = useState(0)
  const [postInformation,setPostInformation] = useState("")

  console.log("Housing",confirmed)

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Calculate total monthly housing expenses
    let monthlyHousingExpenses = 0;

    // Monthly Mortgage or Rent Payment
    monthlyHousingExpenses += mortgageOrRentPayment || 0;

    // Monthly Utilities
    monthlyHousingExpenses += monthlyUtilities || 0;

    // HOA Fees (if applicable)
    if (hasHOA) {
      monthlyHousingExpenses += hoaFees || 0;
    }

    // Mortgage or Apartment Insurance
    monthlyHousingExpenses += insurance || 0;

    // Monthly property taxes and other municipal fees
    monthlyHousingExpenses += propertyTaxes || 0;

    // Home Care (Lawn care, Waste Removal, etc.)
    monthlyHousingExpenses += homeCare || 0;

    // Round to two decimal places
    monthlyHousingExpenses = monthlyHousingExpenses.toFixed(2);

    setMonthlyHousingCalc(monthlyHousingExpenses)

    // Prepare data for API post request
    const postData = [{
      name: 'Housing Expenses',
      budget_estimate: monthlyHousingExpenses,
      budget_type: 'Expense',
      // Add other data you want to send to the API
    }];

    console.log("TRIGGERED")
    
    setPostInformation(postData)
    setConfirmed(true)
  }


  const handleConfirmation = async () => {

    if(monthlyHousingCalc == 0){
      handle()
    }
    else{
      try {
        // Replace with your actual API endpoint
        const response = await fetch(`${baseUrl}/api/intro-budget`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postInformation),
        });
  
        if (response.ok) {
          // Handle success, e.g., show a success message or navigate to the next step
          console.log('API request successful');
          handle();
        } else {
          // Handle error, e.g., show an error message
          console.error('API request failed');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error during API request:', error);
      }
    }
    
  };

  return (
    <div className='h-full '>
      <div className='flex flex-col items-center justify-center h-full text-center'>
        {!confirmed ?
        <>
          <form onSubmit={handleFormSubmit} className='pt-4 pb-0 relative w-[90%] md:w-[50%]'>
            <div id="housing-form" className='relative flex rounded-xl flex-col h-[500px] px-8 overflow-y-scroll scrollbar-thin scrollbar-track-slate-50 scrollbar-thumb-sky-500 scrollbar-rounded-xl'>
              <h3 className="text-[24px] py-5 md:text-[32px] font-bold text-center mx-8 my-2 md:mx-0 underline-offset-1 underline decoration-sky-400">Housing Expenses</h3>
              <div className="my-3">
                <label
                  htmlFor="mortgageOrRentPayment"
                  className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                >
                  Monthly Mortgage or Rent Payment
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                    $
                  </span>
                  <input
                    id="mortgageOrRentPayment"
                    type="number"
                    value={mortgageOrRentPayment}
                    onChange={(e) => setMortgageOrRentPayment(parseFloat(e.target.value))}
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                    required
                  />
                </div>
              </div>
              <div className="my-3">
                <label
                  htmlFor="monthlyUtilities"
                  className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                >
                  Monthly Utilities
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                    $
                  </span>
                  <input
                    id="monthlyUtilities"
                    type="number"
                    value={monthlyUtilities}
                    onChange={(e) => setMonthlyUtilities(parseFloat(e.target.value))}
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                    required
                  />
                </div>
              </div>
              <div className="my-3">
                <label
                  htmlFor="hasHOA"
                  className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                >
                  Are you part of an HOA?
                </label>
                <div className='md:flex mx-auto'>
                  <div className="flex items-center my-4 md:mx-4">
                    <input
                      id="side-hustle-yes"
                      type="radio"
                      value=""
                      checked={hasHOA}
                      onChange={() => setHasHOA(true)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="side-hustle-yes"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="flex items-center my-4 md:mx-4">
                    <input
                      id="side-hustle-no"
                      type="radio"
                      value=""
                      checked={!hasHOA}
                      onChange={() => setHasHOA(false)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="side-hustle-no"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              {hasHOA && (
                <div className="my-2">
                  <label
                    htmlFor="hoaFees"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    HOA Fees
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[14px] md:[18px]">
                      $
                    </span>
                    <input
                      id="hoaFees"
                      type="number"
                      value={hoaFees}
                      onChange={(e) => setHoaFees(parseFloat(e.target.value))}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
                </div>
              )}
              <div className="my-3">
                <label
                  htmlFor="insurance"
                  className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                >
                  Mortgage or Apartment Insurance
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                    $
                  </span>
                  <input
                    id="insurance"
                    type="number"
                    value={insurance}
                    onChange={(e) => setInsurance(parseFloat(e.target.value))}
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                    required
                  />
                </div>
              </div>
              <div className="my-3">
                <label
                  htmlFor="propertyTaxes"
                  className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                >
                  Monthly property taxes and other municipal fees
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                    $
                  </span>
                  <input
                    id="propertyTaxes"
                    type="number"
                    value={propertyTaxes}
                    onChange={(e) => setPropertyTaxes(parseFloat(e.target.value))}
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                    required
                  />
                </div>
              </div>
              <div className="my-3 pb-[3.5rem]">
                <label
                  htmlFor="homeCare"
                  className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                >
                  Home Care (Lawn care, Waste Removal, etc.)
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                    $
                  </span>
                  <input
                    id="homeCare"
                    type="number"
                    value={homeCare}
                    onChange={(e) => setHomeCare(parseFloat(e.target.value))}
                    className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                    required
                  />
                </div>
              </div>
            </div>  
            
            <div className='absolute blur inset-x-0 bottom-0 h-[8rem] md:h-[6rem] bg-gradient-to-t opacity-80 from-85% from-slate-100 to-transparent'></div>

            <div className='py-2 w-full relative flex justify-center items-center'>
                <button
                  type="button"
                  onClick={() => { setConfirmed(false); back(); } }
                  className="z-10 cursor-pointer bg-slate-300 text-[18px] text-black duration-1000 ease-in-out font-bold rounded-2xl w-[90%] px-2 mx-1 h-10 my-1 flex justify-center items-center"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="z-10 cursor-pointer bg-sky-500 text-[18px] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[90%] px-2 mx-1 h-10 my-1 flex justify-center items-center"
                >
                  Continue
                </button>
            </div>
          </form>
        </>
        :
        <div className='py-[5%] my-[5%] outline-dashed outline-2 outline-offset-2 outline-slate-300 rounded-lg'>
          <h1 className='text-[18px] md:text-[24px] px-10'>Your Estimated <span className='underline-offset-1 font-bold underline decoration-sky-400'>Average</span> Monthly Income:</h1>
          <GiHouse className='text-slate-700 h-[6rem] w-[6rem]'/>
          <h1 className='text-[42px] md:text-[48px] text-slate-700 my-1'>$ { monthlyHousingCalc }</h1>
          <h1 className='text-[18px] md:text-[24px] font-medium py-2 text-slate-700'>Does this appear accurate?</h1>
          <div className='flex md:px-10 py-4'>
            <button onClick={() => setConfirmed(false)} className="cursor-pointer bg-slate-300 text-[16px] md:text-[18px] duration-1000 ease-in-out text-black font-bold rounded-2xl w-[75%] md:w-full h-10 my-1 mx-2">Edit Details</button>
            <button onClick={handleConfirmation} className="cursor-pointer bg-sky-500 text-[16px] md:text-[18px] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[75%] md:w-full h-10 my-1 mx-2">Yes, Go Ahead</button>
          </div>
        </div>  }
      </div>
    </div>
  );
};

export default HousingMessage;
