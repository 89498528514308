import React from 'react'
import { useState, useEffect } from 'react'
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";




const DateSelector = ({chooseMonth, chooseYear}) => {

  const month_list = [1,2,3,4,5,6,7,8,9,10,11,12]

  const getInitialMonth = () => {
    const currentDate = new Date();
    const selectMonth = currentDate.getMonth() + 1;

    return selectMonth;
    
  };

  const getInitialYear = () => {
    const currentDate = new Date();
    const selectYear = currentDate.getFullYear();

    console.log(selectYear)

    return selectYear;
    
  };

  const [month, setMonth] = useState(getInitialMonth);
  const [year, setYear] = useState(getInitialYear);

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
    chooseMonth(e.target.value);
    console.log("Month:",e.target.value)
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
    chooseYear(e.target.value);
  };

  const handleMinusOne = () => {
    let month_index= 0
    if(month-1 != 0){
      month_index = month -1
      setMonth(month_index)
      chooseMonth(month_index)

    } else {
      month_index = 12
      setMonth(month_index)
      chooseMonth(month_index)

      chooseYear(year-1)
      setYear(year-1)
    }
  }

  const handlePlusOne = () => {
    let month_index= 0
    if(month+1 != 13){
      month_index = month +1
      setMonth(month_index)
      chooseMonth(month_index)
      
    } else {
      month_index = 1
      setMonth(month_index)
      chooseMonth(month_index)

      chooseYear(year+1)
      setYear(year+1)
    }
  }

  return (
    <div>
      <div class="h-auto bg-[#F1F1F1] rounded-[10px] mt-10 mx-3 md:mx-10 my-3">
        <div class="row-auto pt-3 px-5 ">
          <p class="text-[16px] md:text-[18px] m-0 font-semibold">Financial Period</p>
        </div>
        <div class="row-auto grid grid-cols-12 gap-2 md:gap-1 py-3 px-3 md:px-12">
          <div className='flex items-center justify-center text-center col-span-1 text-slate-300'>
            <FaAngleLeft className='cursor-pointer' onClick={handleMinusOne}/>
          </div>
          <div class="h-auto col-span-5">
            <select class="text-[16px] md:text-[20px] h-[40px] md:pl-5 w-full border-[1px] bg-[#F1F1F1] text-slate-800 semi-bold text-center md:text-left rounded-lg border-slate-300 font-inter" defaultValue={month} value={month} onChange={handleMonthChange}>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div class="h-auto col-span-5">
            <select class="text-[16px] md:text-[20px] h-[40px] md:pl-5 w-full border-[1px] bg-[#F1F1F1] text-slate-800 semi-bold text-center md:text-left rounded-lg border-slate-300 font-inter" defaultValue={year} value={year} onChange={handleYearChange}>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
          </div>
          <div className='flex items-center justify-center text-center col-span-1 text-slate-300'>
            <FaAngleRight className='cursor-pointer' onClick={handlePlusOne}/>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default DateSelector