import React from 'react'
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import {
  Chart,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend
} from 'chart.js'

Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend
)

const BarChart = ({ data: inputData }) => {
    const data = {
        labels: inputData.map(item => item.name),
        datasets: [{
        label: "Actual",
        data: inputData.map(item => item.budget_actual),  // Adjust these values as needed
        backgroundColor: 'rgba(64, 252, 255)',  // Adjust color and transparency
        borderColor: 'rgb(64, 252, 255)',
        borderWidth: 1,
        },
        {
        label: "Budget",
        data: inputData.map(item => item.budget_estimates),  // Adjust these values as needed
        backgroundColor: 'rgba(84, 170, 255)',  // Adjust color and transparency
        borderColor: 'rgb(84, 170, 255)',
        borderWidth: 1,
        },
        ]
    };

    const options = {
        maintainAspectRatio: false, // Allow chart to change size
        aspectRatio: 2,
        scales: {
        x: {
            grid: {
                color: '#425167' // Change the y-axis grid color to blue with some transparency
            },
            ticks: {
                color: '#FFFFFF' // Change the x-axis text color
            }
        },
        y: {
            grid: {
                color: '#425167' // Change the y-axis grid color to blue with some transparency
            },
            ticks: {
                color: '#FFFFFF' // Change the y-axis text color
            }
        }
        },

    };

  return (
    <div className="w-[90%] h-[300px] mx-auto">
        <Bar data={data} options={options}></Bar>
    </div>
  )
}

export default BarChart