import React from 'react'

const Header = ({page_name}) => {
  return (
    <div class="w-[90%] mx-auto my-8">
        <h5 class="text-white m-0 tracking-[0.2em]">{ page_name }</h5>
    </div>
  )
}

export default Header