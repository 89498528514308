import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import SettingsSidebar from '../components/SettingsSidebar'
import AccountSettingsPanel from '../components/AccountSettingsPanel'
import BankSettingsPanel from '../components/BankSettingsPanel'
import SettingsSidebarMobile from '../components/SettingsSidebarMobile'

const AccountSettings = () => {
  const [selectedOption, setSelectedOption] = useState('Account');

  const handleSelectionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div className="">
        <div className="flex flex-col justify-center relative bg-slate-50 w-full min-h-screen text-left text-5xl text-black font-inter">
            <Navbar />

            <div className="hidden lg:block w-[90%] mx-auto my-8 flex-grow">
                <div className='grid grid-cols-12'>
                  <div className='col-span-3 mx-2'>
                    <SettingsSidebar onSelectionChange={handleSelectionChange}/>
                  </div>
                  <div className='col-span-9 flex-grow mx-2'>
                    {selectedOption == 'Account' && <AccountSettingsPanel />}
                    {selectedOption == 'Bank Accounts' && <BankSettingsPanel />}
                  </div>
                </div>
            </div>

            <div className="block lg:hidden w-[100%] mx-auto my-8 flex-grow">
                <div className='grid'>
                  <SettingsSidebarMobile onSelectionChange={handleSelectionChange}/>
                  <div className='flex-grow mx-2'>
                    {selectedOption == 'Account' && <AccountSettingsPanel />}
                    {selectedOption == 'Bank Accounts' && <BankSettingsPanel />}
                  </div>
                </div>
            </div>
            
            
            <Footer />
        </div>
    </div>
  )
}

export default AccountSettings