import React, { useState } from 'react';
import { GiFruitBowl } from "react-icons/gi";
import { MdHealthAndSafety } from 'react-icons/md';

const GroceryMessage = ({ handle, back }) => {
  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }
  
  const authToken = localStorage.getItem('authToken');
  const [weeklyGroceryExpenses, setWeeklyGroceryExpenses] = useState(0);
  const [weeklyRestaurantExpenses, setWeeklyRestaurantExpenses] = useState(0);
  const [healthCareCosts, setHealthCareCosts] = useState(0);
  const [prescriptionsCosts, setPrescriptionsCosts] = useState(0);
  const [personalCareProducts, setPersonalCareProducts] = useState(0);
  const [appearanceExpenses, setAppearanceExpenses] = useState(0);
  
  const [confirmed, setConfirmed] = useState(false);
  const [groceryConfirmed, setGroceryConfirmed] = useState(false);
  const [groceryCalc, setGroceryCalc] = useState(0);
  const [careCalc, setCareCalc] = useState(0);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log(weeklyGroceryExpenses)
    console.log(weeklyRestaurantExpenses)

    let GroceryExpenses = 0
    let RestaurantExpenses = 0
    let FoodExpenses = 0
    let CareCosts = 0

    GroceryExpenses = parseFloat(weeklyGroceryExpenses);
    GroceryExpenses = (GroceryExpenses * 52)/12


    RestaurantExpenses = parseFloat(weeklyRestaurantExpenses);
    RestaurantExpenses = (RestaurantExpenses * 52)/12

    console.log(GroceryExpenses)
    console.log(RestaurantExpenses)

    FoodExpenses = GroceryExpenses + RestaurantExpenses;

    CareCosts = parseFloat(healthCareCosts);
    CareCosts += parseFloat(prescriptionsCosts);
    CareCosts += parseFloat(personalCareProducts);
    CareCosts += parseFloat(appearanceExpenses);

    FoodExpenses = FoodExpenses.toFixed(2);
    CareCosts = CareCosts.toFixed(2);

    setGroceryCalc(FoodExpenses);
    setCareCalc(CareCosts);
    console.log(careCalc)

    setConfirmed(true);
  };

  const handleGroceryConfirmation = async () => {
    const postData = [
      {
        name: 'Groceries',
        budget_estimate: groceryCalc,
        budget_type: 'Expense',
      }
    ];

    if (!(groceryCalc > 0)) {
      setGroceryConfirmed(true);
    } else {
      try {
        const response = await fetch(`${baseUrl}/api/intro-budget`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        });

        if (response.ok) {
          console.log('API request successful');
          setGroceryConfirmed(true);
        } else {
          console.error('API request failed');
        }
      } catch (error) {
        console.error('Error during API request:', error);
      }
    }
  };

  const handleCareConfirmation = async () => {
    const postData = [
      {
        name: 'Personal Care',
        budget_estimate: careCalc,
        budget_type: 'Expense',
      }
    ];

    if (!(careCalc > 0)) {
      handle()
    } else {
      try {
        const response = await fetch(`${baseUrl}/api/intro-budget`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        });

        if (response.ok) {
          console.log('API request successful');
          handle()
        } else {
          console.error('API request failed');
        }
      } catch (error) {
        console.error('Error during API request:', error);
      }
    }
  };

  return (
    <div className='h-full '>
      <div className='flex flex-col items-center justify-center h-full text-center'>
        {!confirmed ? (
          <>
            <form onSubmit={handleFormSubmit} className='pt-4 pb-0 relative w-[90%] md:w-[50%]'>
              <div className='relative flex rounded-xl flex-col h-[500px] px-8 overflow-y-scroll scrollbar-thin scrollbar-track-slate-50 scrollbar-thumb-sky-500 scrollbar-rounded-xl'>
                <h3 className="text-[24px] py-5 md:text-[32px] font-bold text-center mx-8 my-2 md:mx-0 underline-offset-1 underline decoration-sky-400">Food Expenses</h3>
                <div className="my-3">
                  <label
                    htmlFor="weeklyGroceryExpenses"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    Weekly Grocery Expenses
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                      $
                    </span>
                    <input
                      id="weeklyGroceryExpenses"
                      name="weeklyGroceryExpenses"
                      type="number"
                      value={weeklyGroceryExpenses}
                      onChange={(e) => setWeeklyGroceryExpenses(e.target.value)}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
                  <div className="my-3">
                    <label
                      htmlFor="weeklyGroceryExpenses"
                      className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                    >
                      Weekly Restaurant / Takeout Expenses
                    </label>
                    <div className="relative">
                      <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                        $
                      </span>
                      <input
                        id="weeklyRestaurantExpenses"
                        name="weeklyRestaurantExpenses"
                        type="number"
                        value={weeklyRestaurantExpenses}
                        onChange={(e) => setWeeklyRestaurantExpenses(e.target.value)}
                        className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                        required
                      />
                    </div>
                  </div>
                </div>
                <h3 className="text-[24px] py-5 md:text-[32px] font-bold text-center mx-8 my-2 md:mx-0 underline-offset-1 underline decoration-sky-400">Health Expenses</h3>
                <div className="my-3">
                  <label
                    htmlFor="healthCareCosts"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    Monthly Health Care Costs
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                      $
                    </span>
                    <input
                      id="healthCareCosts"
                      name="healthCareCosts"
                      type="number"
                      value={healthCareCosts}
                      onChange={(e) => setHealthCareCosts(e.target.value)}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
                </div>
                <div className="my-3">
                  <label
                    htmlFor="prescriptionsCosts"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    Monthly Prescriptions, vitamins or co-pays
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                      $
                    </span>
                    <input
                      id="prescriptionsCosts"
                      name="prescriptionsCosts"
                      type="number"
                      value={prescriptionsCosts}
                      onChange={(e) => setPrescriptionsCosts(e.target.value)}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
                </div>
                <div className="my-3">
                  <label
                    htmlFor="personalCareProducts"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    Monthly Personal Care & Hygienic Products
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                      $
                    </span>
                    <input
                      id="personalCareProducts"
                      name="personalCareProducts"
                      type="number"
                      value={personalCareProducts}
                      onChange={(e) => setPersonalCareProducts(e.target.value)}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
                </div>
                <div className="my-3 pb-[3.5rem]">
                  <label
                    htmlFor="appearanceExpenses"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    Monthly Appearance Expenses (Hair, Nails, etc.)
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                      $
                    </span>
                    <input
                      id="appearanceExpenses"
                      name="appearanceExpenses"
                      type="number"
                      value={appearanceExpenses}
                      onChange={(e) => setAppearanceExpenses(e.target.value)}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className='absolute blur inset-x-0 bottom-0 h-[8rem] md:h-[6rem] bg-gradient-to-t opacity-80 from-85% from-slate-100 to-transparent'></div>
              <div className='py-2 w-full relative flex justify-center items-center'>
                <button
                  type="button"
                  onClick={() => { setConfirmed(false); back(); }}
                  className="z-10 cursor-pointer bg-slate-300 text-[18px] text-black duration-1000 ease-in-out font-bold rounded-2xl w-[90%] px-2 mx-1 h-10 my-1 flex justify-center items-center"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="z-10 cursor-pointer bg-sky-500 text-[18px] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[90%] px-2 mx-1 h-10 my-1 flex justify-center items-center"
                >
                  Continue
                </button>
              </div>
            </form>
          </>
        ) : (
          !groceryConfirmed ? (
            <div className='py-[5%] my-[5%] outline-dashed outline-2 outline-offset-2 outline-slate-300 rounded-lg'>
              <h1 className='text-[18px] md:text-[24px] px-10'>Your Estimated <span className='underline-offset-1 font-bold underline decoration-sky-400'>Average</span> Food Expenses:</h1>
              <GiFruitBowl className='text-slate-700 h-[6rem] w-[6rem]'/>
              <h1 className='text-[42px] md:text-[48px] text-slate-700 my-1'>$ { groceryCalc }</h1>
              <h1 className='text-[18px] md:text-[24px] font-medium py-2 text-slate-700'>Does this appear accurate?</h1>
              <div className='flex md:px-10 py-4'>
                <button onClick={() => setConfirmed(false)} className="cursor-pointer bg-slate-300 text-[16px] md:text-[18px] duration-1000 ease-in-out text-black font-bold rounded-2xl w-[75%] md:w-full h-10 my-1 mx-2">Edit Details</button>
                <button onClick={() => handleGroceryConfirmation()} className="cursor-pointer bg-sky-500 text-[16px] md:text-[18px] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[75%] md:w-full h-10 my-1 mx-2">Yes, Go Ahead</button>
              </div>
            </div>
          ) : (
            <div className='py-[5%] my-[5%] outline-dashed outline-2 outline-offset-2 outline-slate-300 rounded-lg'>
              <h1 className='text-[18px] md:text-[24px] px-10'>Your Estimated <span className='underline-offset-1 font-bold underline decoration-sky-400'>Average</span> Care Expenses:</h1>
              <MdHealthAndSafety className='text-slate-700 h-[6rem] w-[6rem]'/>
              <h1 className='text-[42px] md:text-[48px] text-slate-700 my-1'>$ { careCalc }</h1>
              <h1 className='text-[18px] md:text-[24px] font-medium py-2 text-slate-700'>Does this appear accurate?</h1>
              <div className='flex md:px-10 py-4'>
                <button onClick={() => setGroceryConfirmed(false)} className="cursor-pointer bg-slate-300 text-[16px] md:text-[18px] duration-1000 ease-in-out text-black font-bold rounded-2xl w-[75%] md:w-full h-10 my-1 mx-2">Edit Details</button>
                <button onClick={() => handleCareConfirmation()} className="cursor-pointer bg-sky-500 text-[16px] md:text-[18px] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[75%] md:w-full h-10 my-1 mx-2">Yes, Go Ahead</button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default GroceryMessage;
