import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import HomeNavbar from '../components/HomeNavbar';
import Footer from '../components/Footer';
import CryptoJS from 'crypto-js';
import Loading from '../components/Loading'

const UserLogin = () => {
  // Get the url from the env file
  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setLoading(true)

      const hashedPassword = CryptoJS.SHA256(formData.password).toString(CryptoJS.enc.Hex);

      console.log(hashedPassword)

      const response = await axios.post(`${baseUrl}/api/user/login`, {
        username: formData.username.toLowerCase(),
        password: hashedPassword,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.error) {
        setLoading(false)
        setError(response.data.error)
        console.log('Login failed:'+response.data.error)
      } else {
      const authToken = response.data.token;

      localStorage.setItem('authToken', authToken);
      // Now you can use authToken as needed (e.g., store it in local storage)
      console.log('Authentication Token:', authToken);
      navigate('/budget');
      }

    } catch (error) {
      // Handle login failure (e.g., display an error message)
      console.error('Login failed:', error.message);
      setLoading(false)
    }
  };

  return (
    <div className="relative flex flex-col min-h-screen bg-[#33363B] w-full text-left text-5xl text-black font-inter">
      <HomeNavbar />
      {!loading ? 
      <div className='flex items-center justify-center h-auto my-auto'>
        <form className="w-full md:w-auto mx-[6%] md:mx-[20%] my-auto backdrop-blur-md bg-black/10 py-6 md:py-8 px-6 md:px-16 rounded shadow-sm transition outline outline-1 outline-sky-400 hover:shadow-sky-400 duration-[1000ms] ease-in-out transform hover:shadow-md" onSubmit={handleSubmit}>
          <h2 className="uppercase tracking-wider text-[18px] md:text-2xl text-slate-100 font-bold my-1 mt-4 text-center"><span className='underline decoration-sky-400 decoration-2'>Login</span> to access</h2>
          <h2 className="uppercase tracking-wider text-[18px] md:text-2xl text-slate-100 font-bold my-1 mb-4 text-center">your finances</h2>
          <div className="mb-4 card-body">
            <label className="block uppercase text-slate-200 text-sm font-bold mb-2" htmlFor="username">
              Email
            </label>
            <input
              className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[16px]"
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className=" card-body">
            <label className="block uppercase text-slate-200 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[16px]"
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>

          <div className='text-left mb-3'>
              <span className="text-[12px] ml-2 text-slate-200"><a className="text-sky-400 font-medium no-underline" href='/login/forgot'>Forgot your password</a></span>
          </div>
          
          <div className='mb-3 flex justify-center'>
            <button
              type="submit"
              className="uppercase tracking-wider bg-sky-500 cursor-pointer text-[18px] duration-1000 ease-in-out text-white font-bold py-2 px-4 rounded-xl w-[90%] hover:w-full mx-auto h-10"
            >
              Login
            </button>
          </div>
          {error && (
            <div onClick={()=>setError('')}>
              <div className="bg-[#ffcbc7] text-center border border-red-400 text-red-700 px-4 py-3 rounded relative text-[14px] md:text-[18px]" role="alert">
                <strong className="font-bold mx-1 ">Error:</strong>
                <span className="block inline mx-1 ">{ error }</span>
              </div>
            </div>
            )}
          <div className='text-left'>
              <span className="text-[12px] ml-2 text-slate-200">Don't already have an account? <a className="text-sky-400 font-medium no-underline" href='/signup'>Sign up here</a></span>
          </div>
          
        </form>
      </div> :
        <Loading />
        }

        <Footer/>
    </div>
  );
};

export default UserLogin;
