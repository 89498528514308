import React from 'react';
import { FaTimes } from "react-icons/fa";
import { LiaTimesSolid } from 'react-icons/lia';


const Transaction = ({ transaction_details, onDeleteSuccess, onCardClick, setLoading  }) => {
  const authToken = localStorage.getItem('authToken');
  const flow = transaction_details.type === 'Inflow';

  // Get the url from the env file
  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }

  const handleDelete = async (event) => {

    const apiRequestData = {
      action: 'Delete',
      id: transaction_details.id
    };

    try {
      const response = await fetch(`${baseUrl}/api/edit-transaction`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
            
            // Add any additional headers if needed
          },
          body: JSON.stringify(apiRequestData),
        });

      if (response.ok) {
        // Invoke the callback to notify the parent component (TransactionView) about the successful deletion
        onDeleteSuccess();
      } else {
        // Handle errors if needed
        console.error('Error deleting transaction:', response.statusText);
      }
      } catch (error) {
        // Handle any network-related errors
        console.error('Error making API request:', error);
      }
  }

  const handleCardClick = () => {
    // Invoke the callback to notify the parent component (TransactionView) about the card click
    console.log(transaction_details)
    onCardClick(transaction_details);
  };

  return (
    <div className="card w-[100%] h-auto bg-[#ffffff] rounded-[20px] mx-auto my-5 outline outline-1 outline-slate-100 shadow-md hover:shadow-lg transition-shadow duration-[2000ms] ease-in-out">
      <div className="mx-5 md:mx-6">
        <div className="flex items-center grid grid-cols-12 gap-2 pt-1 pb-1">
          <div className="col-span-7 cursor-pointer" onClick={handleCardClick} >
            
            <div className='flex items-center gap-2'>

              {transaction_details.plaid_data && (<img src={transaction_details.plaid_data.personal_finance_category_icon_url} className='w-6 md:w-8 lg:w-10 mr-2'/>)}
              
              <div className='lg:ml-4'>
                <div className="p-0">
                  <p className="mb-2 mt-0 text-[8pt] md:text-[12pt] text-slate-400">
                    {transaction_details.budget_category}
                  </p>
                </div>
                <h5 className="block lg:hidden text-[12px] sm:text-[16px] md:text-[24px] font-semibold my-1">
                  {transaction_details.name.length > 10
                    ? `${transaction_details.name.substring(0, 10)}..`
                    : transaction_details.name
                  }
                </h5>
                <h5 className="hidden lg:block xl:hidden text-[12px] sm:text-[16px] md:text-[24px] font-semibold my-1">
                  {transaction_details.name.length > 25
                    ? `${transaction_details.name.substring(0, 25)}..`
                    : transaction_details.name
                  }
                </h5>
                <h5 className="hidden xl:block text-[12px] sm:text-[16px] md:text-[24px] font-semibold my-1">
                  {transaction_details.name.length > 35
                    ? `${transaction_details.name.substring(0, 35)}..`
                    : transaction_details.name
                  }
                </h5>
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div>
              {flow ? (
                <h5 className="text-[12px] sm:text-[14px] md:text-[24px] text-right font-semibold text-green-700">
                  + ${transaction_details.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h5>
              ) : (
                <h5 className="text-[12px] sm:text-[14px] md:text-[24px] text-right font-semibold text-red-600">
                  ( ${transaction_details.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} )
                </h5>
              )}
            </div>
          </div>
          <div className="col-span-1">
            <div className='cursor-pointer'>
              <LiaTimesSolid onClick={handleDelete} className="cursor-pointer text-slate-400 w-3 sm:w-4 md:w-6 align-middle text-center pl-[10%] md:pl-[25%] lg:pl-[25%]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
