import React from 'react';
import { useState, useEffect } from 'react';

const BudgetCategory = ({ type, budget }) => {
  
  let Items = budget.filter((budget) => budget.budget_type === type);

  return (
    <div>
      <div className="h-auto bg-[#ffffff] rounded-[10px] mx-3 md:mx-10 my-5 shadow-lg">
        <div className="row-auto bg-slate-200 rounded-t-[10px]">
          <p className="text-[12px] md:text-[14px] m-0 ml-2 py-1 font-medium text-slate-600">{type}</p>
        </div>
        {Items.map((item, index) => (
          <div key={index}>
            <div className="row-auto grid grid-cols-12 gap-2 py-2 px-2 lsm:px-5 content-center">
              <div class={index} className="col-span-6 md:col-span-6 h-auto">
                <p className="block sm:hidden text-[16px] md:text-[24px] font-semibold">
                  {item.name.length > 10
                    ? `${item.name.substring(0, 10)}..`
                    : item.name
                  }
                </p>
                <p className="hidden sm:block text-[16px] md:text-[24px] font-semibold">
                  {item.name}
                </p>
              </div>
              <div className="col-span-6 md:col-span-6 h-auto my-auto">
                <div className="row-auto">
                  <p className="text-[16px] md:text-[24px] m-0 text-right font-semibold">
                    ${' '}
                    {item.budget_actual.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    <span
                      className={`text-[12px] md:text-[16px] ${
                        item.budget_variance === 0
                          ? 'text-slate-400' // Set the text color to gray for 0% variance
                          : type === 'Income' && item.budget_variance > 0
                          ? 'text-green-500' // Set the text color to green for positive variance in income
                          : item.budget_variance < 0 && type === 'Expense'
                          ? 'text-green-500' // Set the text color to green for negative variance in expenses
                          : 'text-red-100'
                      }`}
                    >
                      {item.budget_variance === 0 ? '(-%)' : `(${item.budget_variance}%)`}
                    </span>
                  </p>
                </div>
                <div className="row-auto">
                  <p className="text-[12px] md:text-[16px] mx-0 my-1 text-right text-[#959595]">
                    Budget: ${item.budget_estimate.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </div>
            </div>

            {index !== Items.length - 1 && (
              <hr className="w-full h-px my-2 bg-[#F1F1F1]"></hr>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BudgetCategory;
