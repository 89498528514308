import React from 'react';
import { Link } from 'react-router-dom';

const HomeNavbar = () => {
  return (
    <nav className="relative items-center justify-between pt-6 md:pt-3 !pb-0 bg-lightBlue-500 mb-3 bg-[#212529] font-inter">
        <div className="flex flex-col items-center justify-center">
            <div class="flex items-center justify-center self-center text-[24px] md:text-[28px] font-light whitespace-nowrap text-white mb-3">
                <a className='no-underline text-white tracking-tighter font-[600]' href="/"><span className='underline decoration-[1px] lg:decoration-[3px] hover:decoration-sky-600 decoration-sky-400 transition-decoration ease-in-out duration-1000'>Open</span>Budget</a>
                <button class="mx-3 3 py-1 px-2 my-auto shadow-md no-underline rounded-full bg-sky-400 text-white font-sans font-semibold text-[8px] md:text-[8px] border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none mr-2">
                    Alpha v1.0
                </button>
            </div>
        </div>
        <hr className="w-[100%] h-[1px] my-0 bg-slate-700"></hr>
        <div className="flex items-center justify-center text-[14px] md:text-[16px] px-4 py-2 bg-[#15191c]/25">
            <Link to="/login" className="mx-4 text-white no-underline px-2">Login</Link>
            <Link to="/signup" className="mx-4 text-white no-underline px-2">Sign Up</Link>
            <Link to="/about" className="mx-4 text-white no-underline px-2">About</Link>
        </div>
        <hr className="w-[100%] h-[1px] my-0 bg-slate-700"></hr>
    </nav>
  );
};

export default HomeNavbar;
