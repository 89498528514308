import React from 'react'

const IntroductionMessage = ({handle}) => {
  return (
    <div className='flex flex-col items-center justify-center h-full text-center px-6'>
        <h3 className="text-[32px] font-bold my-8 mx-8 md:mx-0">Introduction to <span className='font-light'><span class="text-deepskyblue-100 font-bold">Easy</span>Budget</span></h3>
        <h3 className="md:mx-[10%] text-[18px] md:text-[24px] font-medium my-6 px-4">Before we get you started on your financial journey we just need to answer a few questions</h3>
        <button type="submit" onClick={handle} className="cursor-pointer md:mx-[10%] bg-sky-500 text-[18px] hover:bg-sky-600 hover:scale-[101%] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[75%] md:w-[25%] h-10 my-6">
            Get Started
        </button>
    </div>
  )
}

export default IntroductionMessage