import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import BudgetItem from '../components/BudgetItem';
import BudgetEntry from '../components/BudgetEntry';
import PlaidMatch from '../components/PlaidMatch';
import BlankCard from '../components/BlankCard';
import IntroductionWorkflow from '../components/IntroductionWorkflow';
import Loading from '../components/Loading';

const BudgetSettings = () => {
  // Get the url from the env file
  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }

  const [loading, setLoading] = useState(true);
  const [budget, setBudget] = useState([]);
  const [showModal, setShowModal] = useState(false); // New state for modal
  const [dataChange, setDataChange] = useState(false); // New state for modal
  const [introFlow, SetIntroFlow] = useState(false);
  const [showPlaidModal, setShowPlaidModal] = useState(false);

  const getInitialMonth = () => {
    const currentDate = new Date();
    const selectMonth = currentDate.getMonth() + 1;
    return selectMonth;
  };

  const getInitialYear = () => {
    const currentDate = new Date();
    const selectYear = currentDate.getFullYear();
    return selectYear;
  };

  const [month, setMonth] = useState(getInitialMonth);
  const [year, setYear] = useState(getInitialYear);

  useEffect(() => {
    // Function to fetch data from your API
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await fetch(
          `${baseUrl}/api/get-budget/${year}/${month}`,
          {
            headers: {
              'Authorization': `Token ${authToken}`,
              'Content-Type': 'application/json',
            },
          }
        );
        const jsonData = await response.json();

        setBudget(jsonData);
        console.log(jsonData);
        console.log(jsonData.length);

        if ((jsonData && jsonData.length > 1)) {
          SetIntroFlow(false);
        } else {
          SetIntroFlow(true);
        }

        setLoading(false);
        setDataChange(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [year, month, dataChange]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePlaidOpenModal = () => {
    setShowPlaidModal(true);
  };

  const handlePlaidCloseModal = () => {
    setShowPlaidModal(false);
  };

  const hasExpenseItems = budget.some(item => item.budget_type === 'Expense' && item.budget_estimate !== 0);
  const hasIncomeItems = budget.some(item => item.budget_type === 'Income' && item.budget_estimate !== 0);


  function handleFlowComplete() {
    SetIntroFlow(false)
    setDataChange(true)
  }

  // Filter income items
  const incomeItems = budget.filter(item => item.budget_type === 'Income');
  // Filter expense items
  const expenseItems = budget.filter(item => item.budget_type === 'Expense');
  
  // Calculate total income
  const totalIncome = incomeItems.reduce((total, item) => total + item.budget_estimate, 0);
  // Calculate total expense
  const totalExpense = expenseItems.reduce((total, item) => total + item.budget_estimate, 0);
  
  // Calculate monthly budget total
  const monthlyBudgetTotal = (totalIncome - totalExpense);
  
  let monthlyBudgetPercentage
  
  if(totalIncome != 0){
    monthlyBudgetPercentage = ((monthlyBudgetTotal / totalIncome) * 100)
  } else{
    monthlyBudgetPercentage = 0
  }

  
  
  useEffect(() => {
    document.body.style.overflowY = showModal || showPlaidModal || introFlow ? 'hidden' : 'auto';

    return () => {
      // Clean up the effect: Reset overflow when the component unmounts
      document.body.style.overflowY = 'auto';
    };
  }, [showModal, showPlaidModal, introFlow]);


  return (
    <div className={`text-slate-600 absolute bg-slate-50 w-full min-h-screen text-left text-5xl font-inter`}>
      <Navbar />
      <div class="w-[90%] mx-auto my-8">
        <h5 class="m-0 tracking-[0.2em]">UPDATE BUDGET SETTINGS</h5>
      </div>
      {introFlow ? 
      <IntroductionWorkflow complete={handleFlowComplete}/>
      :
      <div></div>
      }

      {!loading ? (
        <div className='mb-20 relative'>
          {!introFlow ? 
          <div className='md:mx-[4rem] mx-5'>
            <h2 className='font-medium text-[18px] my-2'>Estimated Monthly Budget Savings:</h2>
            <h2 className='my-2'>$ {monthlyBudgetTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
              <span className='mx-2 text-[20px]'>
                ({ monthlyBudgetPercentage.toFixed(2) }%)
              </span>
            </h2>
            
            
          </div>
          
          : <></>}
          
          {hasExpenseItems || hasIncomeItems ? (
            <>
              <BudgetItem type={'Income'} budget={budget} 
              onDelete={() => {setDataChange(true)
                  setLoading(true)
              }} />
              <hr className="w-[90%] h-px my-2 bg-slate-300"></hr>
              {hasExpenseItems ? (
              <BudgetItem type={'Expense'} budget={budget} 
              onDelete={() => {setDataChange(true) 
                  setLoading(true)}} /> ):
              (<BlankCard height={300} message={"There are currently no expense items outlined in your budget"}/>)
              }
            </>
            ):(<BlankCard height={400} message={"There are currently no items outlined in your budget"} linkName={"intro budget"} handleLinkClick={() => {SetIntroFlow(true)}}/>)}

            <BudgetEntry showModal={showModal} closeModal={handleCloseModal} dataRefresh={() => {setDataChange(true)}} />
            <PlaidMatch showModal={showPlaidModal} closeModal={handlePlaidCloseModal} budget={budget} />
        </div>
      ) : (
        <Loading dark={true}/>
      )}
      <Footer />
    </div>
  );
};

export default BudgetSettings;
