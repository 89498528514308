import React, { useEffect, useState } from 'react';
import { usePlaidLink, PlaidLinkOptions, PlaidLinkOnSuccess } from "react-plaid-link";
import PlaidSVG from "../images/Plaid.svg"
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaTimes } from 'react-icons/fa';
import { LiaTimesSolid } from "react-icons/lia";


const BankSettingsPanel = () => {

    // Get the url from the env file
    const env = process.env.REACT_APP_PROD_ENV

    let baseUrl

    if(env === "production"){
      baseUrl = process.env.REACT_APP_PROD_URL
    }
    else {
      baseUrl = process.env.REACT_APP_DEV_URL
    }

    const authToken = localStorage.getItem('authToken');
    const [linkToken, setLinkToken] = useState(null)
    const [accounts,setAccounts] = useState(null)
    const [change,setChange] = useState(null)
    const [loading, setLoading] = useState(true)
    
    const getAccessToken = async () =>{
      const response = await fetch(`${baseUrl}/api/create_link_token`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      const link_token = data.link_token
      console.log("Got link token from the server",link_token)
      setLinkToken(link_token)
      open()
    }
  
    const getInfo = async () =>{
      const response = await fetch(`${baseUrl}/api/linked-accounts`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      console.log(data)
      setAccounts(data)
      setLoading(false)
    }
  
    const config = {
      onSuccess: async (public_token, metadata) => {
        setLoading(true)
        console.log(public_token)
        setLoading(true)
        const response = await fetch(`${baseUrl}/api/set_access_token`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body: `public_token=${public_token}`,
        })
        console.log(response)
        setChange(true)
        setLoading(false)
        
      },
      onExit: (err, metadata) => {},
      onEvent: (eventName, metadata) => {},
      token: linkToken,
    };
  
    const { open, exit, ready } = usePlaidLink(config);

    const accountClick = async ({ id }) => {
      const response = await fetch(`${baseUrl}/api/get_account_transactions`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: id
          }),
        });
      
      console.log(response.body)

    }

    const accountDelete = async ({ account_id }) => {
      const response = await fetch(`${baseUrl}/api/remove-bank`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            account_id: account_id
          }),
        });
      
      setChange(true)
      console.log(response.body)

    }
  
    useEffect(() => {
      
      if (ready) {
        open();
      }
    }, [ready, open]);

    useEffect(() => {
      getInfo()
      setChange(false)
    }, [change])

    return (
      <div>
        <h1 className='my-0 py-4 md:pt-10 px-8 w-full text-[24px]'>Bank Accounts</h1>
        <hr className='my-0 py-0 border-b mx-2 border-slate-300' />
        <div className='relative h-full mb-[30%] lsm:mb-[25%] md:mb-[20%] lg:mb-[15%] xl:mb-[10%]'>
          {!loading ? (
            <div>
              <div className='pt-1'>
                <div className='outline outline-1 outline-slate-300 rounded-lg py-2 px-4 mt-4 bg-white'>
                    <h1 className='text-[18px] font-bold text-slate-700'>Linked Bank Accounts for Transactions</h1>
                    <p className='text-slate-500 text-[14px] lg:w-[90%]'>Like many modern fintechs, we leverage Plaid to simplify your financial experience. By linking your account we only have access to retrieve your transaction history. We store your account name and transaction history for easy retrieval, but you are able to delete this data at anytime.</p>
                    <div className='my-4'>
                      <div className='my-7'>
                        <button className='group cursor-pointer py-2 lg:py-1 uppercase bg-slate-200 hover:bg-slate-200 font-bold flex text-[18px] text-black items-center my-auto px-6 rounded-md shadow-md' type="button" large onClick={() => getAccessToken()}>
                          <img
                              src={PlaidSVG}
                              className="mr-1 w-[35px] lg:w-[40px] h-auto text-white"
                          />
                          Link With Plaid
                          <MdKeyboardArrowRight className='group-hover:translate-x-1 transition duration-700 ease-in-out'/>
                        </button>
                      </div>     
                  </div>
                </div>
              </div>
              <div className='py-1'>
                <div className='outline outline-1 outline-slate-300 rounded-lg py-2 px-4 my-4 bg-white'>
                    <h1 className='text-[18px] font-bold text-slate-700'>Account Linked to your Profile</h1>
                    <p className='text-slate-500 text-[14px]'>Please enter the new password you want to use to log in with EasyBudget.</p>
                    {accounts && accounts.map((account, index) => (
                      <div id={account.id} className='items-center grid grid-cols-12 my-6 py-2 px-6 rounded-md w-[80%] lg:w-[50%] outline outline-1 outline-slate-300 shadow-md'>
                        <div onClick={() => accountClick(account)} className='col-span-10 cursor-pointer'>
                          <h1 className='text-[12px] md:text-[14px] lg:text-[18px] my-1 text-semibold text-slate-800'>{account.account_name}</h1>
                          <p className='capitalize my-1 text-[12px] md:text-[14px]'>{account.account_subtype} - ${Number(account.account_balance).toLocaleString()}</p>
                        </div>
                        <div onClick={() => accountDelete(account)} className='col-span-2 justify-center text-center cursor-pointer'>
                          <LiaTimesSolid className="cursor-pointer text-slate-400 h-3 w-3 md:h-7 md:w-7 align-middle text-center pl-2 md:pl-7" />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-[65vh]" role="status text-center">
                    <svg aria-hidden="true" className="w-16 h-16 text-slate-300 animate-spin fill-sky-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <h1 className="text-xl px-20 py-5 text-center text-slate-700">Tallying up the impulse purchases <span className='text-sky-400'>...</span></h1>
              </div>
            )}

        </div>

      </div>
        
        
    )
}

export default BankSettingsPanel