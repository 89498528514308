import React, { useEffect, useState } from 'react'

const SettingsSidebar = ({ onSelectionChange }) => {

  const [selection,setSelection] = useState('Account')

  const handleSelect = ({ name }) => {
    setSelection(name);
    onSelectionChange(name);
  }

  useEffect(() => {
    // Check if authToken is available
    console.log(selection)
  }, [selection]);

  return (
    <div className='py-5 sticky-top-0'>
        <div onClick={ () => handleSelect({ name: 'Account'})} className={`rounded-lg py-2 px-8 my-4 ${selection === 'Account' ? 'bg-slate-200' : ''}`}>
            <h1 className='text-[14px] font-medium text-slate-500'>Account</h1>
        </div>
        <div onClick={ () => handleSelect({name: 'Bank Accounts'})} className={`rounded-lg py-2 px-8  my-4 ${selection === 'Bank Accounts' ? 'bg-slate-200' : ''}`}>
            <h1 className='text-[14px] font-medium text-slate-500'>Bank Accounts</h1>
        </div>
        <div onClick={ () => handleSelect({name: 'Notifications'})} className={`rounded-lg py-2 px-8  my-4 ${selection === 'Notifications' ? 'bg-slate-200' : ''}`}>
            <h1 className='text-[14px] font-medium text-slate-500'>Notifications</h1>
        </div>
        <div onClick={ () => handleSelect({name: 'User Data'})} className={`rounded-lg py-2 px-8  my-4 ${selection === 'User Data' ? 'bg-slate-200' : ''}`}>
            <h1 className='text-[14px] font-medium text-slate-500'>User Data</h1>
        </div>
        
        
    </div>
  )
}

export default SettingsSidebar