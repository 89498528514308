import React, { useState } from 'react';
import { FaGears } from "react-icons/fa6";


const SubscriptionsMessage = ({ handle, back }) => {

  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }

  const [confirmed, setConfirmed] = useState(false)


  const authToken = localStorage.getItem('authToken');
  const [gym, setGym] = useState(0);
  const [internet, setInternet] = useState(0);
  const [phone, setPhone] = useState(0);
  const [streaming, setStreaming] = useState(0);
  const [app, setApp] = useState(0);
  const [newspaper, setNewspaper] = useState(0);
  const [monthlySubscriptionExpenses, setMonthlySubscriptionExpenses] = useState(0);
  const [postInformation, setPostInformation] = useState("");


  const handleFormSubmit = (e) => {
    e.preventDefault();

    let expenseTotal = 0;

    expenseTotal += gym || 0;
    expenseTotal += internet || 0;
    expenseTotal += phone || 0;
    expenseTotal += streaming || 0;
    expenseTotal += app || 0;
    expenseTotal += newspaper || 0;

    setMonthlySubscriptionExpenses(expenseTotal)

    // Prepare data for API post request
    const postData = [{
      name: 'Subscription Expenses',
      budget_estimate: expenseTotal,
      budget_type: 'Expense',
    }];


    setPostInformation(postData);
    setConfirmed(true);
  }

  const handleConfirmation = async () => {
    console.log("SUBSCRIPTION",monthlySubscriptionExpenses)
    if (monthlySubscriptionExpenses == 0) {
      handle();
    } else {
      try {
        // Replace with your actual API endpoint
        const response = await fetch(`${baseUrl}/api/intro-budget`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postInformation),
        });

        if (response.ok) {
          // Handle success, e.g., show a success message or navigate to the next step
          console.log('API request successful');
          handle();
        } else {
          // Handle error, e.g., show an error message
          console.error('API request failed');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error during API request:', error);
      }
    }
  };



  return (
    <div className='h-full '>
      <div className='flex flex-col items-center justify-center h-full text-center'>
        {!confirmed ?
        <>
          <form onSubmit={handleFormSubmit} className='pt-4 pb-0 relative w-[90%] md:w-[50%]'>
            <div id="housing-form" className='relative flex rounded-xl flex-col h-[500px] px-8 overflow-y-scroll scrollbar-thin scrollbar-track-slate-50 scrollbar-thumb-sky-500 scrollbar-rounded-xl'>
              <h3 className="text-[24px] py-5 md:text-[32px] font-bold text-center mx-8 my-2 md:mx-0 underline-offset-1 underline decoration-sky-400">Subscription Expenses</h3>
              <div className="my-3">
                  <label
                    htmlFor="mortgageOrRentPayment"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    Gym
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                      $
                    </span>
                    <input
                      id="mortgageOrRentPayment"
                      type="number"
                      value={gym}
                      onChange={(e) => setGym(parseFloat(e.target.value))}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
              </div>
              <div className="my-3">
                  <label
                    htmlFor="mortgageOrRentPayment"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    Internet
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                      $
                    </span>
                    <input
                      id="mortgageOrRentPayment"
                      type="number"
                      value={internet}
                      onChange={(e) => setInternet(parseFloat(e.target.value))}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
              </div>
              <div className="my-3">
                  <label
                    htmlFor="mortgageOrRentPayment"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    Phone
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                      $
                    </span>
                    <input
                      id="mortgageOrRentPayment"
                      type="number"
                      value={phone}
                      onChange={(e) => setPhone(parseFloat(e.target.value))}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
              </div>
              <div className="my-3">
                  <label
                    htmlFor="mortgageOrRentPayment"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    Phone Apps
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                      $
                    </span>
                    <input
                      id="mortgageOrRentPayment"
                      type="number"
                      value={app}
                      onChange={(e) => setApp(parseFloat(e.target.value))}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
              </div>
              <div className="my-3">
                  <label
                    htmlFor="mortgageOrRentPayment"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    Streaming Entertainment
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                      $
                    </span>
                    <input
                      id="mortgageOrRentPayment"
                      type="number"
                      value={streaming}
                      onChange={(e) => setStreaming(parseFloat(e.target.value))}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
              </div>
              <div className="my-3">
                  <label
                    htmlFor="mortgageOrRentPayment"
                    className="block text-gray-800 text-[14px] md:text-[18px] font-semibold text-left mb-2"
                  >
                    Newspaper
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-700 bg-slate-200 text-[18px] md:[24px]">
                      $
                    </span>
                    <input
                      id="mortgageOrRentPayment"
                      type="number"
                      value={newspaper}
                      onChange={(e) => setNewspaper(parseFloat(e.target.value))}
                      className="box-border shadow appearance-none border rounded w-full py-2 px-3 pl-[4rem] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[18px] md:[24px]"
                      required
                    />
                  </div>
              </div>
            </div>
            <div className='absolute blur inset-x-0 bottom-0 h-[8rem] md:h-[6rem] bg-gradient-to-t opacity-80 from-85% from-slate-100 to-transparent'></div>


          <div className='py-2 w-full relative flex justify-center items-center'>
              <button
                type="button"
                onClick={() => { setConfirmed(false); back(); } }
                className="z-10 cursor-pointer bg-slate-300 text-[18px] text-black duration-1000 ease-in-out font-bold rounded-2xl w-[90%] px-2 mx-1 h-10 my-1 flex justify-center items-center"
              >
                Back
              </button>
              <button
                type="submit"
                className="z-10 cursor-pointer bg-sky-500 text-[18px] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[90%] px-2 mx-1 h-10 my-1 flex justify-center items-center"
              >
                Continue
              </button>
          </div>
          </form>
        </>
        :
          <div className='py-[5%] my-[5%] outline-dashed outline-2 outline-offset-2 outline-slate-300 rounded-lg'>
            <h1 className='text-[18px] md:text-[24px] px-10'>Your Estimated <span className='underline-offset-1 font-bold underline decoration-sky-400'>Average</span> Subscription Expense:</h1>
            <FaGears className='text-slate-700 h-[6rem] w-[6rem]' />
            <h1 className='text-[42px] md:text-[48px] text-slate-700 my-1'>$ {monthlySubscriptionExpenses}</h1>
            <h1 className='text-[18px] md:text-[24px] font-medium py-2 text-slate-700'>Does this appear accurate?</h1>
            <div className='flex md:px-10 py-4'>
              <button onClick={() => setConfirmed(false)} className="cursor-pointer bg-slate-300 text-[16px] md:text-[18px] duration-1000 ease-in-out text-black font-bold rounded-2xl w-[75%] md:w-full h-10 my-1 mx-2">Edit Details</button>
              <button onClick={handleConfirmation} className="cursor-pointer bg-sky-500 text-[16px] md:text-[18px] duration-1000 ease-in-out text-white font-bold rounded-2xl w-[75%] md:w-full h-10 my-1 mx-2">Yes, Go Ahead</button>
            </div>
          </div>
        }
      </div>    
    
    </div>
  )
}

export default SubscriptionsMessage