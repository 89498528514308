import React from 'react'
import { FaMoneyCheckAlt } from "react-icons/fa";


const BlankCard = ({message, height, link, linkName, handleLinkClick }) => {
  return (
    <div className="bg-slate-100 rounded-[10px] mx-3 md:mx-10 my-5 mb-[50px]">
    <div className={`flex flex-col items-center justify-center py-10 h-[350px]`}>
        <div className='my-auto px-4 text-center'>
            <FaMoneyCheckAlt className='text-sky-500 text-center w-12 h-12' />
            <h1 className=' text-[16px] my-4 text-center md:text-[22px] text-slate-700 font-regular'>{message}</h1>
            <h3 className='text-[16px] mx-10 my-4 text-center md:text-[18px] text-slate-500 font-medium'>Visit the <a className='text-sky-500 no-underline cursor-pointer' href={link} onClick={handleLinkClick}>{ linkName }</a> page to create a new entry</h3>
        </div>
    </div>
    </div>

  )
}

export default BlankCard