import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import TransactionView from './pages/TransactionView';
import BudgetViewResponsive from './pages/BudgetViewResponsive';
import UserLogin from './pages/UserLogin';
import BudgetSettings from './pages/BudgetSettings'
import UserRegistration from './pages/UserRegistration';
import PasswordReset from './pages/PasswordReset';
import PasswordForget from './pages/PasswordForget';
import LandingPage from './pages/LandingPage';
import LandingPageMobile from './pages/LandingPageMobile';
import LinkPlaid from './pages/LinkPlaid';
import AccountSettings from './pages/AccountSettings';



const ProtectedRoute = ({ element }) => {
  // Check for the presence of the authentication token
  const isAuthenticated = !!localStorage.getItem('authToken');

  // If the user is authenticated, render the provided element
  // Otherwise, redirect to the login page
  return isAuthenticated ? element : <Navigate to="/login" />;
};

const HomeRoute = ({ element }) => {
  // Check for the presence of the authentication token
  const isAuthenticated = !!localStorage.getItem('authToken');

  // If the user is authenticated, render the provided element
  // Otherwise, redirect to the login page
  return isAuthenticated ? <Navigate to="/budget" /> : element ;
};

const App = () => {
  useEffect(() => {
    // Your other setup code here
  }, []);

  return (
    <div className='overflow-hidden h-full overscroll-none'>
      <Routes>
        <Route path="/" element={<HomeRoute element={<LandingPage />} />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/login/forgot" element={<PasswordForget />} />
        <Route path="/login/reset/:UID/:Token" element={<PasswordReset />} />
        <Route path="/signup" element={<UserRegistration />} />
        <Route path="/signup/:LandingEmail" element={<UserRegistration />} />

        <Route
          path="/budget"
          element={<ProtectedRoute element={<BudgetViewResponsive />} />}/>
        <Route
          path="/transactions"
          element={<ProtectedRoute element={<TransactionView />} />}/>
        <Route
          path="/settings"
          element={<ProtectedRoute element={<BudgetSettings />} />}/>
        <Route
          path="/account"
          element={<ProtectedRoute element={<AccountSettings />} />}/>
      </Routes>
    </div>
    
  );
};

export default App;
