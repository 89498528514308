import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import BudgetEntry from '../components/BudgetEntry';
import { LiaTimesSolid } from 'react-icons/lia';
import DOMPurify from 'dompurify'; 



const BudgetItem = ({ type, budget, onDelete }) => {

  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }

  const [selectedBudget, setSelectedBudget] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const Items = budget.filter((budgetItem) => {
    const hasCorrectType = budgetItem.budget_type === type;
    const doesNotContainUncategorized = !budgetItem.name.toLowerCase().includes("uncategorized");

    return hasCorrectType && doesNotContainUncategorized;
  });

  const handleDeleteClick = async (budgetId) => {
    // Call the onDelete function passed as a prop to handle the deletion
    const apiRequestData = {
        action: "Delete",
        id: budgetId,
      };

    try {
    const authToken = localStorage.getItem('authToken');

    const response = await fetch(`${baseUrl}/api/edit-budget`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(apiRequestData),
      });

    // Check if the request was successful
    if (response.ok) {
        console.log('API request successful:', apiRequestData);
        // Clear the form data or perform any other necessary actions
        onDelete()
      } else {
        // Handle the case where the API request was not successful
        console.error('API request failed:', response.statusText);
      }
    } catch (error) {
      // Handle any network-related errors
      console.error('Error making API request:', error);
    }
  };

  const handleEditClick = (budgetItem) => {
    // Set the selected budget item and show the modal
    setSelectedBudget(budgetItem);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    // Close the modal and reset the selected budget item
    setShowModal(false);
    setSelectedBudget(null);
  };


  return (
    <div className={`${type == "Expense" && 'mb-[35%] lsm:mb-[25%] md:mb-[20%] lg:mb-[15%] xl:mb-[10%]'} md:mx-10 mx-2 my-5`}>
      {type == 'Income' ? 
        <h1 className='text-slate-400 text-[12px] md:text-[14px]  font-medium px-5 py-0 my-6'>Sources of {type}</h1>
        :
        <h1 className='text-slate-400 text-[12px] md:text-[14px]  font-medium px-5 py-0 my-6'>{type}s</h1>
        }
      
      {Items.map((item, index) => (
        <div
          key={index}
          className="card w-[100%] h-auto bg-[#ffffff] rounded-[20px] mx-auto my-2 outline outline-1 outline-slate-100 shadow-sm"
        >
          <div className="mx-5 md:mx-10">
            <div className="flex items-center grid grid-cols-12 gap-2 pt-1 pb-1">
              <div className="col-span-7 cursor-pointer" onClick={() => handleEditClick(item)}>
                <div>
                  {item.budget_icon != "None" && (
                  <div className="content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.budget_icon)}}>
                  </div>
                  )}
                  <h5 className="text-[12pt] md:text-[24px] font-semibold my-1">
                    {item.name}
                  </h5>
                </div>
              </div>
              <div className="col-span-4">
                <div>
                  {type === 'Income' ? (
                    <h5 className="text-[12pt] md:text-[24px] text-right my-3 font-semibold text-green-700">
                      + ${item.budget_estimate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h5>
                  ) : (
                    <h5 className="text-[12pt] md:text-[24px] text-right my-3 font-semibold text-red-700">
                      (${item.budget_estimate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })})
                    </h5>
                  )}
                </div>
              </div>
              <div className="col-span-1">
                <div className='cursor-pointer' onClick={() => handleDeleteClick(item.id)}>
                  <LiaTimesSolid className="text-slate-400 w-4 md:w-6 align-middle text-center pl-2 md:pl-7" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div
          className="card w-[100%] h-auto bg-[#ffffff] rounded-[20px] mx-auto my-2 outline outline-1 outline-slate-100 shadow-sm"
        >
          <div className="mx-5 md:mx-10">
            <div className="flex items-center grid grid-cols-12 gap-2 pt-1 pb-1">
              <div className="col-span-7">
                <div>
                  {type === 'Income' ? (
                  <h5 className="text-[12pt] md:text-[24px] font-semibold my-1 text-slate-400">
                   Uncategorized 
                  </h5>) : 
                  ( <h5 className="text-[12pt] md:text-[24px] font-semibold my-1 text-slate-400">
                  Uncategorized 
                 </h5>)}
                </div>
              </div>
              <div className="col-span-4">
                <div>
                  {type === 'Income' ? (
                    <h5 className="text-[12pt] md:text-[24px] text-right my-3 font-semibold text-slate-400">
                      + $ --
                    </h5>
                  ) : (
                    <h5 className="text-[12pt] md:text-[24px] text-right my-3 font-semibold text-slate-400">
                      ($ -- )
                    </h5>
                  )}
                </div>
              </div>
              <div className="col-span-1">
                
              </div>
            </div>
          </div>
        </div>

      {showModal && (
        <BudgetEntry
          showModal={showModal}
          closeModal={handleCloseModal}
          budget={selectedBudget}
          dataRefresh={() => onDelete()}
        />
      )}
    </div>
  );
};

export default BudgetItem;
