import React, { useEffect, useState } from 'react';
import { usePlaidLink, PlaidLinkOptions, PlaidLinkOnSuccess } from "react-plaid-link";
import PlaidSVG from "../images/Plaid.svg"
import { MdKeyboardArrowRight } from "react-icons/md";



function Link() {

    // Get the url from the env file
    const env = process.env.REACT_APP_PROD_ENV

    let baseUrl

    if(env === "production"){
      baseUrl = process.env.REACT_APP_PROD_URL
    }
    else {
      baseUrl = process.env.REACT_APP_DEV_URL
    }
  
    const authToken = localStorage.getItem('authToken');
    const [linkToken, setLinkToken] = useState(null)
    const [info,setInfo] = useState(null)
    
    const getAccessToken = async () =>{
      const response = await fetch(`${baseUrl}/api/create_link_token`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      const link_token = data.link_token
      console.log("Got link token from the server",link_token)
      setLinkToken(link_token)
      open()
    }
  
    const getInfo = async () =>{
      const response = await fetch(`${baseUrl}/api/identity`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      console.log(data)
      const off_name = data.identity[0].official_name;
      setInfo(off_name)
    }
  
    const config = {
      onSuccess: async (public_token, metadata) => {
        console.log(public_token)
        const response = await fetch(`${baseUrl}/api/set_access_token`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body: `public_token=${public_token}`,
        })
        console.log(response)
        
      },
      onExit: (err, metadata) => {},
      onEvent: (eventName, metadata) => {},
      token: linkToken,
    };
  
    const { open, exit, ready } = usePlaidLink(config);
  
    useEffect(() => {
      if (ready) {
        open();
      }
    }, [ready, open]);
    
  
    return (
      <div className='flex flex-col w-full'>
        <div className=''>
          <div className='px-[10%] lg:px-[20%]'>
            <h2 className='mx-auto my-7 text-center text-white text-[24px] lg:text-[34px]'>
              Automatically link transactions from your bank accounts and credit cards
            </h2>
            <h2 className='mx-auto my-7 text-center text-slate-300 font-medium text-[14px] lg:text-[20px] leading-relaxed'>
              Like many modern fintechs, we leverage Plaid to simplify your financial experience. By linking your account we only have access to retrieve your transaction history. We store your account name and transaction history for easy retrieval, but you are able to delete this data at anytime.
            </h2>
          </div>
          <div className='flex justify-center my-7 '>
            <button className='group cursor-pointer py-2 lg:py-1 uppercase bg-white hover:bg-white font-bold flex text-[18px] lg:text-[24px] text-black items-center my-auto px-6 lg:px-10 rounded-xl hover:scale-105 transition duration-700 ease-in-out' type="button" large onClick={() => getAccessToken()}>
              
              <img
                  src={PlaidSVG}
                  className="mr-1 w-[35px] lg:w-[45px] h-auto text-white"
              />
              Link With Plaid
              <MdKeyboardArrowRight className='group-hover:translate-x-1 transition duration-700 ease-in-out'/>
            </button>
          </div>
        </div>
        

        {info &&
          <h1>{ info }</h1>
        }
        
      </div>
    );
  }
  
  export default Link;