import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import HomeNavbar from '../components/HomeNavbar';
import Footer from '../components/Footer';
import CryptoJS from 'crypto-js';

const UserRegistration = () => {

  // Get the url from the env file
  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }
  
  const { LandingEmail } = useParams();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");


  const [formData, setFormData] = useState({
    username: LandingEmail || '',
    password: '',
    password2: '',
    terms:''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    if(formData.password != formData.password2){
      e.preventDefault();
      setError('Passwords do not match')
      console.log('Registration failed: Passwords do not match')
    } else{
    e.preventDefault();

    try {
      setLoading(true)

      const hashedPassword = CryptoJS.SHA256(formData.password).toString(CryptoJS.enc.Hex);


      console.log("HASHED:",hashedPassword)

      const response = await axios.post(`${baseUrl}/api/user/registration`, {
        username: formData.username,
        password: hashedPassword,
        terms: formData.terms,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.error) {
        setLoading(false)
        setError(response.data.error)
        console.log('Registration failed:'+response.data.error)
      } else {
        const authToken = response.data.token;

        localStorage.setItem('authToken', authToken);
        // Now you can use authToken as needed (e.g., store it in local storage)
        console.log('Authentication Token:', authToken);
        navigate('/');
      }

    } catch (error) {
      // Handle login failure (e.g., display an error message)
      console.error('Login failed:', error.message);
      setLoading(false)
    }}
  };

  return (
    <div className="relative flex flex-col bg-[#33363B] w-full min-h-screen text-left text-5xl text-black font-inter">
      <HomeNavbar />
      
      {!loading ?
      <div className='flex items-center justify-center h-auto my-auto'>
        <form className="w-full md:w-auto mx-[6%] md:mx-[20%] my-auto backdrop-blur-md bg-black/10 py-6 md:py-8 px-6 md:px-16 rounded shadow-sm transition outline outline-1 outline-sky-400 hover:shadow-sky-400 duration-[1000ms] ease-in-out transform hover:shadow-md" onSubmit={handleSubmit}>
            <h2 className="uppercase tracking-wider text-[18px] md:text-2xl text-slate-100 font-bold my-1 mt-4 text-center"><span className='underline decoration-sky-400 decoration-2'>Sign up</span> to create</h2>
            <h2 className="uppercase tracking-wider text-[18px] md:text-2xl text-slate-100 font-bold my-1 mb-4 text-center">your first budget</h2>
            <div className="mb-4 card-body">
                <label className="block uppercase text-slate-200 text-sm font-bold mb-2" htmlFor="username">
                Email
                </label>
                <input
                className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[16px]"
                type="email"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
                />
            </div>
            <div className="mb-6 card-body">
                <label className="block uppercase text-slate-200 text-sm font-bold mb-2" htmlFor="password">
                Password
                </label>
                <input
                className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[16px]"
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                />
            </div>
            <div className="mb-6 card-body">
                <label className="block uppercase text-slate-200 text-sm font-bold mb-2" htmlFor="password2">
                Confirm Password
                </label>
                <input
                className="box-border shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-solid border-zinc-200 text-[16px]"
                type="password"
                id="password2"
                name="password2"
                value={formData.password2}
                onChange={handleChange}
                required
                />
            </div>
          
            <div className="mb-6 card-body">
                <label className="flex items-center">
                <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    className="form-checkbox h-4 w-4"
                    value={formData.terms}
                    onChange={handleChange}
                    required
                />
                <span className="text-[14px] ml-2 text-slate-200">I agree to the terms and conditions</span>
                </label>
            </div>
          
            <div className='mb-3 flex justify-center'>
                <button type="submit" className="uppercase tracking-wider bg-sky-500 cursor-pointer text-[18px] duration-1000 ease-in-out text-white font-bold py-2 px-4 rounded-xl w-[90%] hover:w-full mx-auto h-10">
                Get Started
                </button>
            </div>
            {error && (
            <div onClick={()=>setError('')}>
              <div className="bg-[#ffcbc7] text-center border border-red-400 text-red-700 px-4 py-3 rounded relative text-[14px] md:text-[18px]" role="alert">
                <strong className="font-bold mx-1 ">Error:</strong>
                <span className="block inline mx-1 ">{ error }</span>
              </div>
            </div>
            )}
            <div className='text-center '>
              <span className="text-[12px] ml-2 text-slate-200">Already have an account? <a className="text-sky-400 font-medium no-underline" href='/login'>Sign in here</a></span>
            </div>
            
          </form>
        </div>

         :

         <div className="flex flex-col my-auto items-center justify-center" role="status text-center">
            <svg aria-hidden="true" className="w-16 h-16 text-white animate-spin dark:text-white fill-sky-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <h1 className="text-xl px-20 py-5 text-center text-white">Gathering your information <span className='text-sky-400'>...</span></h1>
        </div>}

        <Footer />
    </div>
)}

export default UserRegistration