import React from 'react'

const Footer = () => {
  return (
    <footer class="bg-[#212529] h-[100px] shadow dark:bg-gray-800 w-full absolute mt-4 bottom-0 outline outline-top-1 outline-slate-700 text-center">
        <div class="text-white h-full flex items-center justify-center">
            <div class="px-5 my-auto">
                <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400 text">
                    <a href="/" class="text-white hover:underline text-[14pt]">OpenBudget</a>
                </span>
                <ul class="list-none no-underline decoration-none flex flex-wrap items-center px-0 my-3 text-sm font-medium text-gray-500 dark:text-gray-400">
                    <li>
                        <a href="#" class="no-underline text-slate-400 text-center">Developer Feedback Form</a>
                    </li>
                    <span className='mx-2'>|</span>
                    <li>
                        <a href="#" class="no-underline text-slate-400 text-center">Privacy Policy</a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>


  )
}

export default Footer