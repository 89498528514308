import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';


const AccountSettingsPanel = () => {
  // Get the url from the env file
  const env = process.env.REACT_APP_PROD_ENV

  let baseUrl

  if(env === "production"){
    baseUrl = process.env.REACT_APP_PROD_URL
  }
  else {
    baseUrl = process.env.REACT_APP_DEV_URL
  }

  const authToken = localStorage.getItem('authToken');
  const navigate = useNavigate();

  const [email, setEmail] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [action, setAction] = useState('')
  const [emailSuccess, setEmailSuccess] = useState('')
  const [passwordSuccess, setPasswordSuccess] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [deleteError, setDeleteError] = useState(null)
  const [loading, setLoading] = useState(true)


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPassword(''); // Reset the password input when closing the modal
  };

  const confirmEmail = async () => {
    console.log(action)
    setLoading(true)
    const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

    if(action == 'email'){
      try {
        // Make a POST request to confirm the email change
        const response = await fetch(`${baseUrl}/api/edit-email`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: hashedPassword,
          }),
        });
    
        // Check if the request was successful (status code 2xx)
        if (response.ok) {
          
          const responseData = await response.json(); // Extract JSON data
          console.log(responseData);
          console.log(responseData.token)

          localStorage.clear()
          const authToken = responseData.token;
          localStorage.setItem('authToken', authToken);

          setEmailSuccess('Success! Email is updated.')
          console.log('Email change confirmed successfully');
          setEmail(email)
          setPassword('')
          setLoading(false)
          // Add logic here if needed for a successful confirmation
        } else {
          setPassword('')
          console.log(response)
          console.error('Error confirming email change:', response.statusText);
          setEmailError('Could not update email')
          setLoading(false)
          // Add logic here for handling errors
        }
      } catch (error) {
        setPassword('')
        console.error('Error confirming email change:', error);
        setEmailError('Could not update email')
        // Add logic here for handling errors
      }
    }
    if(action == 'password'){

      const hashedNewPassword = CryptoJS.SHA256(newPassword).toString(CryptoJS.enc.Hex);

      try {
        // Make a POST request to confirm the email change
        const response = await fetch(`${baseUrl}/api/edit-password`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password: hashedPassword,
            newPassword: hashedNewPassword
          }),
        });
    
        // Check if the request was successful (status code 2xx)
        if (response.ok) {

          const responseData = await response.json(); // Extract JSON data
          console.log(responseData);
          console.log(responseData.token)

          localStorage.clear()
          const authToken = responseData.token;
          localStorage.setItem('authToken', authToken);

          setPasswordSuccess('Success! Password is updated.')
          console.log('Password change confirmed successfully');
          setPassword('')
          setNewPassword('')
          setLoading(false)
          // Add logic here if needed for a successful confirmation
        } else {
          setPassword('')
          setNewPassword('')
          console.log(response)
          console.error('Error confirming email change:', response.statusText);
          setPasswordError('Could not update email')
          setLoading(false)
          // Add logic here for handling errors
        }
      } catch (error) {
        setPassword('')
        setNewPassword('')
        console.error('Error confirming email change:', error);
        setPasswordError('Could not update email')
        // Add logic here for handling errors
      }
    }
    if(action == 'delete'){
      try {
        // Make a POST request to confirm the email change
        const response = await fetch(`${baseUrl}/api/delete-account`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password: hashedPassword
          }),
        });
    
        // Check if the request was successful (status code 2xx)
        if (response.ok) {

          localStorage.clear()
          setLoading(false)

          console.log('Account has been deleted');
          navigate('/login');

          // Add logic here if needed for a successful confirmation
        } else {
          setPassword('')
          setLoading(false)

          setDeleteError('Could not verify password.')

          console.log(response)

        }
      } catch (error) {
        setPassword('')
        setLoading(false)

        setDeleteError('Could not verify password.')

        console.error('Error confirming email change:', error);

      }
    }
  
    // Close the modal
    closeModal();
  };
  

  useEffect(() => {
    // Check if authToken is available
    if (authToken) {
      // Make a GET request to the server to retrieve user information
      fetch(`${baseUrl}/api/get-info`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        // Set the user data in the state
        console.log(data)
        setEmail(data.email);
        setLoading(false)
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        setLoading(false)
      });
    }
  }, [authToken, emailError, passwordError]);



  return (
    <div>
      <h1 className='my-0 py-4 md:pt-10 px-8 w-full text-[24px]'>Account Settings</h1>
      <hr className='my-0 py-0 border-b mx-2 border-slate-300' />
      {!loading ? (
      <div className='relative h-full mb-[30%] lsm:mb-[25%] md:mb-[20%] lg:mb-[15%] xl:mb-[10%]'>
        <div className='pt-1'>
          <div className='outline outline-1 outline-slate-300 rounded-lg py-2 px-4 mt-4 bg-white'>
              <h1 className='text-[18px] font-bold text-slate-700'>Account Email Change</h1>
              <p className='text-slate-500 text-[14px]'>Please enter the email address you want to use to log in with EasyBudget.</p>
              <input type="email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='w-[90%] lg:w-[50%] text-[16px] h-10 px-2 outline outline-1 outline-slate-200 rounded-md'/>
              {emailSuccess && 
              <div onClick={()=>setEmailSuccess('')}>
                <div className="bg-[#c7ffcc] w-[48%] text-left border border-green-400 text-green-700 px-4 py-3 rounded-md relative text-[12px] mt-2 md:text-[14px]" role="alert">
                  <span className="inline mx-1 ">{ emailSuccess }</span>
                </div>
              </div>}
              {emailError && 
              <div onClick={()=>  setEmailError('')}>
                <div className="bg-[#ffcbc7] w-[48%] text-left border border-red-400 text-red-700 px-4 py-3 rounded-md relative text-[12px] mt-2 md:text-[14px]" role="alert">
                  <strong className="font-bold mx-1 ">Error:</strong>
                  <span className="inline mx-1 ">{ emailError }</span>
                </div>
              </div>}
              <div className='my-4'>
                <button onClick={() =>{openModal(); setAction('email');}} className='text-[18px] py-2 px-6 rounded-md bg-slate-500 text-white font-semibold'>Save Changes</button>
              </div>
          </div>
        </div>
        <div className='py-1'>
          <div className='outline outline-1 outline-slate-300 rounded-lg py-2 px-4 my-4 bg-white'>
              <h1 className='text-[18px] font-bold text-slate-700'>Account Password Change</h1>
              <p className='text-slate-500 text-[14px]'>Please enter the new password you want to use to log in with EasyBudget.</p>
              <input type="password" 
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className='w-[90%] lg:w-[50%] text-[16px] h-10 px-2 outline outline-1 outline-slate-200 rounded-md'/>
              {passwordSuccess && 
              <div onClick={()=>setPasswordSuccess('')}>
                <div className="bg-[#c7ffcc] w-[48%] text-left border border-green-400 text-green-700 px-4 py-3 rounded-md relative text-[12px] mt-2 md:text-[14px]" role="alert">
                  <span className="inline mx-1 ">{ passwordSuccess }</span>
                </div>
              </div>}
              {passwordError && 
              <div onClick={()=>  setPasswordError('')}>
                <div className="bg-[#ffcbc7] w-[48%] text-left border border-red-400 text-red-700 px-4 py-3 rounded-md relative text-[12px] mt-2 md:text-[14px]" role="alert">
                  <strong className="font-bold mx-1 ">Error:</strong>
                  <span className="inline mx-1 ">{ passwordError }</span>
                </div>
              </div>}
              <div className='my-4'>
                <button onClick={() =>{openModal(); setAction('password');}} className='text-[18px] py-2 px-6 rounded-md bg-slate-500 text-white font-semibold'>Save Changes</button>
              </div>
          </div>
        </div>
        <div className='py-1'>
          <div className='outline outline-1 outline-red-300 rounded-lg py-2 px-4 my-4 bg-white'>
              <h1 className='text-[18px] font-bold text-red-700'>Delete Account</h1>
              <p className='text-slate-500 text-[14px] w-full lg:w-[80%]'>Permanently remove your Personal Account and all of its contents from the EasyBudget platform. This action is not reversible, so please continue with caution.</p>
              {deleteError && 
              <div onClick={()=>  setDeleteError('')}>
                <div className="bg-[#ffcbc7] w-[48%] text-left border border-red-400 text-red-700 px-4 py-3 rounded-md relative text-[12px] mt-2 md:text-[14px]" role="alert">
                  <strong className="font-bold mx-1 ">Error:</strong>
                  <span className="inline mx-1 ">{ deleteError }</span>
                </div>
              </div>}
              <div className='my-4'>
                <button onClick={() =>{openModal(); setAction('delete');}} className='text-[18px] py-2 px-6 rounded-md bg-red-500 text-white font-semibold'>Delete Account</button>
              </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg">
              <h1 className="text-[24px] font-bold mb-4">Confirm Information Change</h1>
              <p className="text-sm mb-4">Please enter your password to confirm the new email:</p>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full h-10 px-2 outline outline-1 outline-slate-200 rounded-md mb-4"
              />
              <div className="flex space-x-4">
                <button onClick={closeModal} className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md font-semibold">Cancel</button>
                <button onClick={confirmEmail} className="px-4 py-2 bg-slate-500 text-white rounded-md font-semibold">Confirm</button>
              </div>
            </div>
          </div>
        )}

      </div> ): (
        <div className="flex flex-col items-center justify-center h-[65vh]" role="status text-center">
            <svg aria-hidden="true" className="w-16 h-16 text-slate-300 animate-spin fill-sky-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <h1 className="text-xl px-20 py-5 text-center text-slate-700">Tallying up the impulse purchases <span className='text-sky-400'>...</span></h1>
        </div>
      )
      }

    </div>
    
    
  )
}

export default AccountSettingsPanel