import React, { useState, useEffect } from 'react';
import IntroductionMessage from './IntroductionWorkflow/IntroductionMessage';
import SalaryMessage from './IntroductionWorkflow/SalaryMessage';
import HousingMessage from './IntroductionWorkflow/HousingMessage';
import GroceryMessage from './IntroductionWorkflow/GroceryMessage';
import TransportationMessage from './IntroductionWorkflow/TransportationMessage';
import CareMessage from './IntroductionWorkflow/CareMessage';
import SubscriptionsMessage from './IntroductionWorkflow/SubscriptionsMessage';
import LuxuryMessage from './IntroductionWorkflow/LuxuryMessage';
import SavingsMessage from './IntroductionWorkflow/SavingsMessage';


const IntroductionWorkflow = ({ complete }) => {
    const [introduction, setIntroduction] = useState(true)
    const [salary, setSalary] = useState(false)
    const [housing, setHousing] = useState(false)
    const [transportation, setTransportation] = useState(false)
    const [grocery, setGrocery] = useState(false)
    const [care, setCare] = useState(false)
    const [subscription, setSubscription] = useState(false)
    const [luxury, setLuxury] = useState(false)
    const [savings, setSavings] = useState(false)


  
    const handleIntroductionMessage = () => {
        setIntroduction(false)
        setHousing(false)
        setSalary(true)
    }

    const handleSalaryMessage = () => {
        setSalary(false)
        setGrocery(false)
        setHousing(true)
    }

    const handleHousingMessage = () => {
        setHousing(false)
        setTransportation(false)
        setGrocery(true)
        
    }

    const handleGroceryMessage = () => {
        setGrocery(false)
        setTransportation(true)
    }
    
    const handleTransportationMessage = () => {
        setTransportation(false)
        setSubscription(true)
    }

    const handleSubscriptionMessage = () => {
        setSubscription(false)
        setLuxury(true)
    }

    const handleLuxuryMessage = () => {
        setLuxury(false)
        complete()
        setSavings(true)
    }

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="bg-slate-800 absolute inset-0 bg-gray-800 opacity-75 backdrop-blur-3xl"></div>
                    <div className="bg-slate-100 px-0 py-4 md:p-8 z-50 md:rounded-2xl shadow-lg w-[100%] md:w-[80%] h-[100%] md:h-[80%] mx-0 md:!mx-2">
                    {introduction ? <IntroductionMessage handle={handleIntroductionMessage}/> : <></>}
                    {salary ? <SalaryMessage handle={handleSalaryMessage}/> : <></>}
                    {housing ? <HousingMessage handle={handleHousingMessage} back={handleIntroductionMessage} /> : <></>}
                    {grocery ? <GroceryMessage handle={handleGroceryMessage} back={handleSalaryMessage}/> : <></>}
                    {transportation ? <TransportationMessage handle={handleTransportationMessage} back={handleHousingMessage}/> : <></>}
                    {subscription ? <SubscriptionsMessage handle={handleSubscriptionMessage} back={handleGroceryMessage}/> : <></>}
                    {luxury ? <LuxuryMessage handle={handleLuxuryMessage} back={handleTransportationMessage}/> : <></>}
                    {savings ? <SavingsMessage handle={handleTransportationMessage}/> : <></>}
                </div>
            </div>
        </>
    );
};

export default IntroductionWorkflow;
